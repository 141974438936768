export const COLUMNS = [
  {
    Header: 'Angel Information',
    columns: [
      {
        Header: 'Angel ID',
        accessor: 'childId',
      },
      {
        Header: 'Angel Name',
        accessor: 'childName',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Angel Wish',
        accessor: 'wish',
      },
    ],
  },
  {
    Header: `Angel's Parent`,
    columns: [
      {
        Header: 'Parent ID',
        accessor: 'parentId',
      },
      {
        Header: 'Parent Name',
        accessor: 'parentName',
      },
      {
        Header: 'Approved Status',
        accessor: 'approved',
      },
    ],
  },

  {
    Header: 'Sponsorship Information',
    columns: [
      {
        Header: 'Sponsor ID',
        accessor: 'sponsorId',
      },
      {
        Header: 'Sponsored By',
        accessor: 'sponsoredBy',
      },
      {
        Header: 'Sponsor Email',
        accessor: 'sponsorEmail',
      },
    ],
  },
];
