import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  Alert,
  Button,
  DropdownButton,
  Dropdown,
  Container,
  Spinner,
  ButtonGroup,
  Row,
  Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteManyParents,
  deleteSingleParent,
  selectManyParents,
} from './parentFormSlice';
import { fetchParentInfo } from './parentInfoSlice';
import CustomTable from '../../customs/CustomTable';
import { COLUMNS } from './parentColumns';
import { IoAdd, IoTrash } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

const ParentInfo = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const [setTotalParents] = useOutletContext();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const parentInfo = useSelector((state) => state.parent.parentInfo);
  const parentForm = useSelector((state) => state.parent.parentForm);

  const { loading, error, data } = parentInfo;
  const { deleteLoading } = parentForm;

  useEffect(() => {
    dispatch(fetchParentInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) setTotalParents(data.length);
    return () => {
      setTotalParents(0);
    };
  }, [data.length, setTotalParents]);

  const handleBulkUpdateApprovedStatus = (selectedParents) => {
    dispatch(selectManyParents(selectedParents));
    navigate('edit/approved-status');
  };

  const handleBulkUpdatePickupStatus = (selectedParents) => {
    dispatch(selectManyParents(selectedParents));
    navigate('edit/pickup-status');
  };

  const handleDeleteSingleParent = (userId) => {
    if (window.confirm('Do you really want to delete this Parent?')) {
      dispatch(deleteSingleParent(userId))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchParentInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleDeleteManyParents = (rows) => {
    if (window.confirm('Do you really want to delete these Parents?')) {
      const userIds = rows.map((row) => row.userId);
      dispatch(deleteManyParents(userIds))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchParentInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (error)
    return (
      <Container className='mt-5'>
        <Alert className='text-center' variant='danger'>
          {error}
        </Alert>
      </Container>
    );

  if (loading) {
    return (
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '50vh' }}
      >
        <Spinner animation='border' role='status' variant='success'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='m-3 m-sm-3 m-md-3'>
      <Row className='flex-column align-items-center justify-content-center'>
        <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
          <Button as={Link} to={'new'} variant='primary'>
            <IoAdd size={25} /> New Parent
          </Button>
        </Col>
        {selectedRows.length === 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                as={Link}
                to={`edit/${selectedRows[0].userId}`}
                variant='outline-secondary'
              >
                <RiEdit2Fill size={25} /> Edit Parent
              </Button>
            </Col>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                variant='outline-danger'
                disabled={deleteLoading}
                onClick={() => handleDeleteSingleParent(selectedRows[0].userId)}
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete Parent
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}

        {selectedRows.length > 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <DropdownButton
                id='dropdown-basic-button'
                title={`Update ${selectedRows.length} Parents`}
                align={{ lg: 'end' }}
                as={ButtonGroup}
                variant='outline-secondary'
              >
                <Dropdown.Item
                  onClick={() => handleBulkUpdateApprovedStatus(selectedRows)}
                >
                  Aprroved Status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleBulkUpdatePickupStatus(selectedRows)}
                >
                  Pickup Status
                </Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                variant='outline-danger'
                disabled={deleteLoading}
                onClick={() => handleDeleteManyParents(selectedRows)}
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete {selectedRows.length} Parents
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}
      </Row>
      <div>
        {data.length > 0 ? (
          <CustomTable
            data={data}
            columns={COLUMNS}
            fileName='parent-information'
            headerStyles={{ backgroundColor: '#009879', color: '#ffffff' }}
            setSelectedRows={setSelectedRows}
          />
        ) : (
          <div className='d-flex flex-row justify-content-center mt-5'>
            <Alert className='w-75 text-center' variant='warning'>
              No parents/caregivers. Parent can be added from the Admin panel or
              from Public Angel Tree website when they register an application.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentInfo;
