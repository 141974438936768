// Request interceptor for API calls
import TokenService from './TokenService';
import instance from './api';

import { refreshToken } from '../components/auth/authSlice';

const setupInterceptors = (store) => {
  instance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  // Response interceptor for API calls with refresh token with the jwt token is expired
  const interceptorsResponse = instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (payload) => {
      // Reject promise if unusual payload
      if (payload.response.status !== 401) {
        return Promise.reject(payload);
      }
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      instance.interceptors.response.eject(interceptorsResponse);

      try {
        // Getting the accessToken using cookie
        const res = await instance.get('/auth/refresh');
        TokenService.updateLocalAccessToken(res.data.accessToken);

        // Update auth reducer
        dispatch(refreshToken(res.data.accessToken));
        payload.response.config.headers[
          'Authorization'
        ] = `Bearer ${res.data.accessToken}`;

        return instance(payload.response.config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );
};
export default setupInterceptors;
