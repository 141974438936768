import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import setupInterceptors from '../services/setupInterceptors';
import { getCurrentUser } from '../components/auth/authSlice';


const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development mode
});

// Setup interceptors to access store inside axios interceptors
setupInterceptors(store);

store.dispatch(getCurrentUser());

export default store;
