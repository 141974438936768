import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Badge,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomPieChart from '../../customs/CustomPieChart';
import { fetchStatInfo } from './statInfoSlice';
import { AiOutlineDashboard } from 'react-icons/ai';
import CustomBarChart from '../../customs/CustomBarChart';

const Dashboard = () => {
  const dispatch = useDispatch();
  const statInfo = useSelector((state) => state.stat);

  const { loading, error, data } = statInfo;

  useEffect(() => {
    dispatch(fetchStatInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch]);

  const summaryArray = useMemo(() => {
    return [
      {
        heading: 'Total Registered Parents',
        description:
          'Total Registered Parents in the program including ones who are approved and unapproved.',
        number: data?.totalRegisteredParents,
      },
      {
        heading: 'Total Eligible Parents',
        description:
          'Total Eligible Parents who are approved and accepted to be in the program.',
        number: data?.approvedParents,
      },
      {
        heading: 'Total Ineligible Parents',
        description:
          'Total Ineligible Parents who are NOT approved and accepted to be in the program.',
        number: data?.unapprovedParents,
      },
      {
        heading: 'Total Registered Angels',
        description: 'Total Angels are from eligible and ineligible parents.',
        number: data?.totalChildren,
      },
      {
        heading: 'Total Eligible Angels',
        description:
          'Total Eligible Angels whose parents are approved and accepted to be in the program.',
        number: data?.approvedChildren,
      },
      {
        heading: 'Total Ineligible Angels',
        description:
          'Total Ineligible Angels whose parents are NOT approved and accepted to be in the program.',
        number: data?.unapprovedChildren,
      },
      {
        heading: 'Total Sponsored Angels',
        description:
          'Total Eligible Angels who have been sponsored.',
        number: data?.sponsoredChildren,
      },
      {
        heading: 'Total Sponsors',
        description: 'Total Sponsors who are participating in the program.',
        number: data?.totalSponsors,
      },
    ];
  }, [data]);

  const chartArray = useMemo(() => {
    return [
      {
        alert: 'Parent Hightlights',
        charts: [
          {
            type: 'pie',
            data: data?.parentLocations,
            title: `Distribution of Regististed Parents in both locations`,
            COLORS: ['#EF006D', '#0A96C1'],
          },
          {
            type: 'pie',
            data: data?.parentApprovalStatus,
            title: `Parent Distribution on Approval Status`,
            COLORS: ['#4CAF50', '#FF5252'],
          },
          {
            type: 'pie',
            data: data?.approvedParentLocations,
            title: `Distribution of Approved Parents in both locations`,
            COLORS: ['#EF006D', '#0A96C1'],
          },
          {
            type: 'pie',
            data: data?.pickup,
            title: `Distribution of Gift Pick-up`,
            COLORS: ['#026b64', '#FDB91B'],
          },
        ],
      },
      {
        alert: 'Angel Hightlights',
        charts: [
          {
            type: 'pie',
            data: data?.childGenders,
            title: `Distribution of Registered Angel Genders`,
            COLORS: ['#EF006D', '#0A96C1', '#FDB91B'],
          },
          {
            type: 'bar',
            data: data?.childAgeGroup,
            title: `Age Group Distribution of Registered Angels (0-16)`,
            COLORS: ['#1E6091'],
          },
          {
            type: 'pie',
            data: data?.childApprovalStatus,
            title: `Distribution of Angels on Approval Status`,
            COLORS: ['#4CAF50', '#FF5252'],
          },
          {
            type: 'pie',
            data: data?.childSponsorship,
            title: `Distribution of Sponsorship on Approved Angels`,
            COLORS: ['#026b64', '#FDB91B'],
          },
        ],
      },
      {
        alert: 'Sponsor Hightlights',
        charts: [
          {
            type: 'pie',
            data: data?.sponsor,
            title: `Sponsor Distribution in both locations`,
            COLORS: ['#EF006D', '#0A96C1'],
          },
          {
            type: 'pie',
            data: data?.dropoff,
            title: `Distribution of Gift Drop-off`,
            COLORS: ['#026b64', '#FDB91B'],
          },
        ],
      },
    ];
  }, [data]);

  if (error)
    return (
      <Container className='mt-5'>
        <Alert className='text-center' variant='danger'>
          {error}
        </Alert>
      </Container>
    );

  if (loading) {
    return (
      <>
        <Container>
          <div>
            <h1 style={{ fontSize: '60px', letterSpacing: '4px', textAlign: 'center' }}>
              <span>
                <AiOutlineDashboard size={60} />{' '}
              </span>
              Dashboard
            </h1>
          </div>
        </Container>
        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{ height: '50vh' }}
        >
          <Spinner animation='border' role='status' variant='success'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </>
    );
  }

  const renderCharts = (chart) => {
    let RENDERS = <></>;

    switch (chart.type) {
      case 'pie':
        RENDERS = <CustomPieChart
          data={chart?.data}
          title={chart?.title}
          COLORS={chart?.COLORS}
        />
        break;

      case 'bar':
        let dataKeyY = '';
        if (chart?.data[0].hasOwnProperty('Number of Angels')) {
          dataKeyY = 'Number of Angels';
        }
        RENDERS = <CustomBarChart
          data={chart?.data}
          title={chart?.title}
          dataKeyX='dataKeyX'
          dataKeyY={dataKeyY}
          COLORS={chart?.COLORS}
        />
        break;

      default: RENDERS = <></>;
    }
    return RENDERS;
  }

  return (
    <Container fluid className='px-md-5'>
      <div>
        <h1 style={{ fontSize: '60px', letterSpacing: '4px', textAlign: 'center' }}>
          <span>
            <AiOutlineDashboard size={60} />{' '}
          </span>
          Dashboard
        </h1>
      </div>
      {data && (
        <div className='mt-5'>
          <div>
            <Alert className='mt-3' variant='info'>
              <h4>Summary</h4>
            </Alert>

            <Row>
              <Col className=''>
                <ListGroup style={{ width: '100%' }} as='ol' numbered>
                  {summaryArray.map((element, index) => (
                    <ListGroup.Item
                      as='li'
                      className='d-flex justify-content-between align-items-start'
                      key={index}
                    >
                      <div className='ms-2 me-auto'>
                        <div className='fw-bold'>{element.heading}</div>
                        {element.description}
                      </div>
                      <Badge variant='primary' pill>
                        {element.number}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </div>

          <div>
            {chartArray.map((element, index) => (
              <div key={index}>
                <Alert className='mt-3' variant='info'>
                  <h4>{element.alert}</h4>
                </Alert>
                <Row>
                  {element.charts.map((chart, i) => (
                    <Col
                      key={i}
                      className='d-flex justify-content-center align-content-between'
                    >
                      <div className='mt-3'>
                        {renderCharts(chart)}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Dashboard;
