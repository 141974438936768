import { combineReducers } from 'redux';

// Parent slices
import parentInfoSlice from '../components/parent/parentInfoSlice';
import parentFormSlice from '../components/parent/parentFormSlice';

// Child slices
import childInfoSlice from '../components/child/childInfoSlice';
import childFormSlice from '../components/child/childFormSlice';

// Sponsor slices
import sponsorInfoSlice from '../components/sponsor/sponsorInfoSlice';
import sponsorFormSlice from '../components/sponsor/sponsorFormSlice';

// Stat Slice
import statInfoSlice from '../components/Home/statInfoSlice';

// Setting Slices
import adminCodeSlice from '../components/setting/adminCodeSlice';

// Auth Slice
import authSlice from '../components/auth/authSlice';

const parentReducers = combineReducers({
  parentInfo: parentInfoSlice,
  parentForm: parentFormSlice,
});

const childReducers = combineReducers({
  childInfo: childInfoSlice,
  childForm: childFormSlice,
});

const sponsorReducers = combineReducers({
  sponsorInfo: sponsorInfoSlice,
  sponsorForm: sponsorFormSlice,
});

const settingReducers = combineReducers({
  adminCode: adminCodeSlice,
});

const appReducer = combineReducers({
  parent: parentReducers,
  child: childReducers,
  sponsor: sponsorReducers,
  stat: statInfoSlice,
  auth: authSlice,
  setting: settingReducers,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logoutSuccess') state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
