/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useLayoutEffect } from 'react';
import { Button, Stack, Table } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import { IoCaretUpCircleSharp, IoCaretDownCircleSharp } from 'react-icons/io5';
import TableGlobalFilter from './TableGlobalFilter';
import './table.css';
import TableCheckbox from './TableCheckbox';
import { useExportData } from 'react-table-plugins';
import Papa from 'papaparse';
import { FaFileCsv } from 'react-icons/fa';

const CustomTable = (props) => {
  const columns = useMemo(() => props.columns, []);
  const data = useMemo(() => props.data, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    exportData,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      getExportFileName: ({ all }) => {
        return `${all ? `${props?.fileName}` : 'all-data'}`;
      },
    },
    useGlobalFilter,
    useSortBy,
    useExportData,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <TableCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;
  const [pageNumber, setPageNumber] = useState(1);

  const handleChangePageNumber = (e) => {
    setPageNumber(Number(e.target.value));
    gotoPage(Number(e.target.value) - 1);
  };

  useLayoutEffect(() => {
    if (selectedFlatRows.length > 0) {
      const data = [];
      for (let i = 0; i < selectedFlatRows.length; i++) {
        data.push(selectedFlatRows[i].original);
      }
      props.setSelectedRows(data);
    }
    return () => {
      props.setSelectedRows([]);
    };
  }, [selectedFlatRows]);

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === 'csv') {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: 'text/csv' });
    }

    // Other formats goes here
    return false;
  }

  return (
    <>
      <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
        <TableGlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <p className='mt-1 small'>
          Tips: You can click on any column to sort the data in ascending or
          descending order. Click checkbox to edit/update the data.
        </p>
      </div>

      <Table
        className='styled-table'
        striped
        bordered
        hover
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={props.headerStyles}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span>
                          {' '}
                          <IoCaretUpCircleSharp />
                        </span>
                      ) : (
                        <span>
                          {' '}
                          <IoCaretDownCircleSharp />
                        </span>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className='mt-2 clearfix'>
        <Button
          variant='outline-success'
          size='sm'
          className='float-end'
          onClick={() => {
            exportData('csv', true);
          }}
        >
          <span>
            <FaFileCsv size={25} /> Export as CSV
          </span>
        </Button>
      </div>
      <>
        <Stack gap={3}>
          <Stack
            direction='horizontal'
            gap={2}
            className='d-flex justify-content-center align-items-center'
          >
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span>
              | Go to page:{' '}
              <input
                type='number'
                min={1}
                max={pageOptions.length}
                onInput={(e) =>
                  e.target.validity.valid ||
                  (e.target.value = pageOptions.length)
                }
                value={pageNumber}
                onChange={(e) => handleChangePageNumber(e)}
                style={{ width: '50px' }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 125, 150, 200].map(
                (pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                )
              )}
            </select>
          </Stack>

          <Stack
            direction='horizontal'
            gap={2}
            className='d-flex justify-content-center align-items-center'
          >
            <Button
              variant='outline-secondary'
              className='me-auto'
              onClick={() => {
                gotoPage(0);
                setPageNumber(1);
              }}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                previousPage();
                setPageNumber(pageNumber - 1);
              }}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                nextPage();
                setPageNumber(pageNumber + 1);
              }}
              disabled={!canNextPage}
            >
              Next
            </Button>
            <Button
              variant='outline-secondary'
              className='ms-auto'
              onClick={() => {
                gotoPage(pageCount - 1);
                setPageNumber(pageCount);
              }}
              disabled={!canNextPage}
            >
              {'>>'}
            </Button>
          </Stack>
        </Stack>
      </>
    </>
  );
};

export default CustomTable;
