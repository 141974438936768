/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';

const ActivateAccount = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let abortController = new AbortController();

    function sendActivationToken() {
      let res;
      setLoading((prev) => !prev);
      setTimeout(async () => {
        try {
          res = await api.put(
            `/auth/activate?${searchParams.toString()}`,
            {},
            { signal: abortController.signal }
          );
          toast.success(res.data.message);
          navigate('/auth/login', { replace: true });
        } catch (error) {
          if (error.response?.data?.expired) setExpired(true);
          setErrorMessage(error.response?.message);
        } finally {
          setLoading((prev) => !prev);
        }
      }, 500);
    }

    sendActivationToken();

    return () => {
      abortController.abort();
    };
  }, []);

  const handleRequestActivation = () => {
    const activationToken = searchParams.get('activationToken');
    const userId = searchParams.get('userId');

    let abortController = new AbortController();
    let res;

    setRequestLoading((prev) => !prev);
    setTimeout(async () => {
      try {
        res = await api.post(
          '/auth/activate/resend',
          { activationToken, userId },
          { signal: abortController.signal }
        );
        toast.success(res.data.msg);
        navigate('/', { replace: true });
      } catch (error) {
        setErrorMessage(error.response.data.message);
      } finally {
        setRequestLoading((prev) => !prev);
      }
    }, 1500);
  };

  if (loading) {
    return (
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <Spinner animation='border' role='status' variant='success'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center'
      style={{ height: '100vh' }}
    >
      {errorMessage !== '' && (
        <Card className='text-center' border='danger'>
          <Card.Header>Error Message</Card.Header>
          <Card.Body>
            <Card.Title> Account Activation Error </Card.Title>
            <Card.Text>{errorMessage}</Card.Text>
            {expired && (
              <Button
                variant='warning'
                disabled={requestLoading}
                onClick={() => handleRequestActivation()}
              >
                {requestLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Request Activation'
                )}
              </Button>
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ActivateAccount;
