import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
  Form,
  Badge,
  Spinner,
  Card,
  ListGroup,
} from 'react-bootstrap';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

// Import React-Hook-Forms and yup
import { useForm, useFieldArray } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
// Import sponsor actions
import { toast } from 'react-toastify';
import {
  selectManySponsors,
  updateSponsorsDropoffStatus,
} from '../sponsorFormSlice';
import { format } from 'date-fns';

const DropoffStatus = () => {
  const navigate = useNavigate();

  const sponsorForm = useSelector((state) => state.sponsor.sponsorForm);
  const dispatch = useDispatch();

  const { selectedSponsors, updateLoading } = sponsorForm;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    watch,
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'selectedSponsors',
  });

  useEffect(() => {
    if (selectedSponsors.length > 0) {
      const updatedSponsors = selectedSponsors.map((sponsor) => {
        const updatedSponsor = { ...sponsor }; // Create a shallow copy of the sponsor object

        // Update the 'dropoff' property
        if (updatedSponsor.dropoff === 'Yes') {
          updatedSponsor.dropoff = 1;
        } else {
          updatedSponsor.dropoff = 0;
        }

        return updatedSponsor; // Return the updated sponsor object
      });

      setValue('selectedSponsors', updatedSponsors);
    }
  }, [setValue, selectedSponsors]);


  const watching = watch('selectedSponsors');

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to update these Sponsor Statuses?')) {
      dispatch(updateSponsorsDropoffStatus(values.selectedSponsors))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          navigate('/sponsor', { replace: true });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (selectedSponsors.length === 0)
    return (
      <Container className='mt-5'>
        <div>
          <Button
            variant='outline-secondary'
            className='mb-3'
            onClick={() => {
              navigate('/sponsor');
            }}
          >
            <IoArrowBack /> Go Back
          </Button>
          <h1 className='mb-5'>
            <span>
              <FiEdit size={50} />
            </span>{' '}
            Update Dropoff
          </h1>
        </div>
        <Alert variant='warning'>
          No Sponsor(s) selected. Please select more than 2 sponsors .
        </Alert>
      </Container>
    );

  return (
    <Container className='mt-5'>
      <div>
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            dispatch(selectManySponsors([]));
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1 className='mb-5'>
          <span>
            <FiEdit size={50} />
          </span>{' '}
          Update Dropoff{' '}
          <span>
            <Badge style={{ fontSize: '22px' }} pill bg='secondary'>
              {selectedSponsors.length}
            </Badge>
          </span>
        </h1>
      </div>
      {fields.length > 0 && (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          {fields.map((item, index) => (
            <Card key={item.id} className='border-light mb-5'>
              <Card.Header>
                <h4>
                  {item.sponsorName} -{' '}
                  <span className='text-danger fw-bold'>
                    ID: {item.sponsorId}
                  </span>
                </h4>
              </Card.Header>
              <Card.Body>
                {/* Parent Information */}
                <ListGroup variant='flush'>
                  <ListGroup.Item className='d-flex flex-wrap'>
                    Email
                    <span className='fw-bold ms-auto'>{item.email}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex flex-wrap'>
                    Sponsor Title
                    <span className='fw-bold ms-auto'>{item.title}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Number of Angels
                    <span className='fw-bold ms-auto'>
                      {item.numberOfAngels}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Dropoff Location
                    <span className='fw-bold ms-auto'>
                      {item.dropoffLocation} campus
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Dropoff Time
                    <span className='fw-bold ms-auto'>
                      {item.dropoffTime !== null
                        ? format(new Date(item.dropoffTime), 'dd/MM/yyyy p')
                        : 'None'}
                    </span>
                  </ListGroup.Item>
                  {/* Dropoff Status */}
                  <ListGroup.Item>
                    <Form.Group
                      className='d-flex mb-3'
                      controlId='formApproved'
                    >
                      <Form.Label className='me-3'>
                        Dropoff Angel Wish?
                      </Form.Label>

                      <div className='ms-auto'>
                        <Form.Check
                          inline
                          type='radio'
                          name='pickupGroup'
                          id='inline-radio-dropoff'
                          value={1}
                          label='Yes'
                          defaultChecked={item.dropoff === 1}
                          {...register(`selectedSponsors.${index}.dropoff`)}
                        />
                        <Form.Check
                          inline
                          type='radio'
                          name='pickupGroup'
                          id='inline-radio-dropoff'
                          value={0}
                          label='No'
                          defaultChecked={item.dropoff === 0}
                          {...register(`selectedSponsors.${index}.dropoff`)}
                        />
                      </div>
                    </Form.Group>
                  </ListGroup.Item>

                  {/* Send Confirmation email? */}
                  {item.dropoff === 1 || watching[index].dropoff === '1' ? (
                    <ListGroup.Item>
                      <Form.Group
                        className='d-flex mb-3'
                        controlId='formApproved'
                      >
                        <Form.Label className='me-3'>
                          Send Dropoff Confirmation Email?
                        </Form.Label>

                        <div className='ms-auto'>
                          <Form.Check
                            inline
                            type='radio'
                            name='sendEmailGroup'
                            id='inline-radio-email-1'
                            value={1}
                            label='Yes'
                            {...register(`selectedSponsors.${index}.sendEmail`)}
                          />
                          <Form.Check
                            inline
                            type='radio'
                            name='sendEmailGroup'
                            id='inline-radio-email-2'
                            value={0}
                            label='No'
                            {...register(`selectedSponsors.${index}.sendEmail`)}
                            defaultChecked
                          />
                        </div>
                      </Form.Group>
                    </ListGroup.Item>
                  ) : (
                    <></>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          ))}

          <div className='d-grid gap-2'>
            <Button
              variant='success'
              type='submit'
              size='lg'
              disabled={updateLoading || !isDirty}
            >
              {updateLoading ? (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                <span>
                  Save Changes <IoSave />
                </span>
              )}
            </Button>
          </div>
        </Form>
      )}
    </Container>
  );
};

export default DropoffStatus;
