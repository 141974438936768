import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { IoLogOutOutline, IoSettings } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { logoutAccount } from '../auth/authSlice';
import { toast } from 'react-toastify';

const NaviBar = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, user } = authState;

  const handleLogout = () => {
    dispatch(logoutAccount())
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => toast.error(err));
  };

  const AuthRoutes = (
    <>
      <Nav className='me-auto'>
        <LinkContainer to='/'>
          <Nav.Link>Dashboard</Nav.Link>
        </LinkContainer>

        <LinkContainer to='/parent'>
          <Nav.Link>Parent</Nav.Link>
        </LinkContainer>
        <LinkContainer to='/child'>
          <Nav.Link>Angel</Nav.Link>
        </LinkContainer>
        <LinkContainer to='/sponsor'>
          <Nav.Link>Sponsor</Nav.Link>
        </LinkContainer>
        <Nav.Link href='https://angeltree.ufvsus.ca' target='_blank'>
          Public Web
        </Nav.Link>
      </Nav>
      <Nav>
        <NavDropdown
          title={user && `Hello, ${user?.username}`}
          id='collasible-nav-dropdown'
        >
          <LinkContainer to='/setting'>
            <NavDropdown.Item>
              <IoSettings size={20} /> Settings
            </NavDropdown.Item>
          </LinkContainer>
          <NavDropdown.Item onClick={handleLogout}>
            <IoLogOutOutline size={20} /> Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </>
  );

  const PublicRoutes = (
    <Nav>
      <LinkContainer to='/auth/login'>
        <Nav.Link>Login</Nav.Link>
      </LinkContainer>

      <LinkContainer to='/auth/register'>
        <Nav.Link>Register</Nav.Link>
      </LinkContainer>

      <Nav.Link href='https://angeltree.ufvsus.ca' target='_blank'>
        Public Web
      </Nav.Link>
    </Nav>
  );

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      className='mb-5'
      style={{ backgroundColor: '#CE4D4D' }}
    >
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>ANGEL TREE Admin</Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls='responsive-navbar-nav' />

        <Navbar.Collapse id='responsive-navbar-nav'>
          {isAuthenticated ? AuthRoutes : PublicRoutes}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NaviBar;
