import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';
import { VscDebugRestart } from 'react-icons/vsc';

import { useParams, useNavigate } from 'react-router-dom';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
// Import child, sponsor actions
import { getSingleChildInfo, updateSingleChildInfo } from '../childFormSlice';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('First Name Required'),
  age: yup.string().required('Please select age'),
  gender: yup.string().required('Please select gender'),
  wish: yup.string().required('Wish required'),
  selectedSponsor: yup
    .object()
    .shape({
      label: yup.string().nullable(true),
      value: yup.string().nullable(true),
    })
    .nullable()
    .required('Please select a Sponsor or select None'),
});

const EditChild = () => {
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const childForm = useSelector((state) => state.child.childForm);
  const sponsorInfo = useSelector((state) => state.sponsor.sponsorInfo);

  const { loading, updateLoading, error, updateError, data } = childForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      name: data?.childInfo.childName || '',
      age: data?.childInfo.age || '',
      gender: data?.childInfo.gender || '',
      wish: data?.childInfo.wish || '',
      selectedSponsor: {
        value: '',
        label: 'None',
      },
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getSingleChildInfo(params.childId))
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch, params.childId]);

  useEffect(() => {
    let matchedSponsor = {
      value: '',
      label: 'None',
    };
    if (data) {
      if (data?.sponsors.length > 0 && data?.childInfo.sponsorId !== null) {
        const found = data.sponsors.filter(
          (s) => s.sponsorId === data?.childInfo.sponsorId
        );
        matchedSponsor = {
          value: found[0].sponsorId,
          label: `${found[0].sponsorName}, ${found[0].email}`,
        };
      }

      reset({
        name: data?.childInfo.childName,
        age: data?.childInfo.age,
        gender: data?.childInfo.gender,
        wish: data?.childInfo.wish,
        selectedSponsor: matchedSponsor,
      });
    }
  }, [data, sponsorInfo.data, reset]);

  const sponsorOptions = useMemo(() => {
    if (data?.sponsors.length === 0 || data?.sponsors === undefined)
      return [{ value: '', label: 'None' }];

    const sponsorArray = data?.sponsors.map((sponsor) => {
      return {
        value: sponsor.sponsorId,
        label: `${sponsor.sponsorName}, ${sponsor.email}`,
      };
    });

    sponsorArray.sort((a, b) => a.label.localeCompare(b.label)); //sort by sponsorName
    sponsorArray.unshift({
      value: '',
      label: 'None',
    });
    return sponsorArray;
  }, [data?.sponsors]);

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to update this Angel?')) {
      const data = {
        ...values,
        sponsorId: values.selectedSponsor.value,
      };
      delete data.selectedSponsor;

      dispatch(updateSingleChildInfo({ data, childId: params.childId }))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          navigate('/child');
          reset();
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (loading)
    return (
      <Container className='mt-5'>
        <div>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Button
            variant='outline-secondary'
            className='mb-3'
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoArrowBack /> Go Back
          </Button>
          <h1>
            <span>
              <FiEdit size={50} />
            </span>{' '}
            Edit Angel
          </h1>
        </div>

        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{ height: '50vh' }}
        >
          <Spinner animation='border' role='status' variant='success'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </Container>
    );

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        {updateError && <Alert variant='danger'>{updateError}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <FiEdit size={50} />
          </span>{' '}
          Edit Angel{' '}
          {data && (
            <span className='text-danger'>ID {data?.childInfo.childId}</span>
          )}
        </h1>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Child Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Angel's Information</h4>
        </Alert>

        {/* First Name field */}
        <Form.Group className='mb-3' controlId='formBasicName'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type='text'
            {...register(`name`)}
            placeholder='Enter First Name'
            isValid={dirtyFields?.name && !errors?.name}
            isInvalid={errors?.name?.message}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Child Age */}
        <Form.Group className='mb-3' controlId={`age`}>
          <Form.Label>Angel Age</Form.Label>
          <Controller
            name={`age`}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select age'
                id={`age`}
                isValid={dirtyFields?.age && !errors?.age}
                isInvalid={errors?.age}
              >
                <option value=''> Please select age</option>
                {Array(17)
                  .fill()
                  .map((_, i) => (
                    <option key={i} value={Number(i)}>
                      {Number(i)}
                    </option>
                  ))}
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.age?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Child Gender */}
        <Form.Group className='mb-3' controlId={`gender`}>
          <Form.Label>Angel Gender</Form.Label>
          <Controller
            name={`gender`}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select gender'
                id={`gender`}
                isValid={dirtyFields?.gender && !errors?.gender}
                isInvalid={errors?.gender}
              >
                <option value=''> Please select a gender</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.gender?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Child Wish */}
        <Form.Group className='mb-3' controlId={`wish`}>
          <Form.Label>Angel Wish</Form.Label>
          <Form.Control
            as='textarea'
            wrap='hard'
            rows={3}
            col={25}
            placeholder='Kindi Kids Doll, Creative Blue Bricks'
            {...register(`wish`)}
            isValid={dirtyFields?.wish && !errors?.wish}
            isInvalid={errors?.wish}
          />
          <p className='text-muted mt-2'>
            Tip: When submitting multiple wishes, please use a comma(,) to
            separate the wishes like the example above.
          </p>
          <Form.Control.Feedback type='invalid'>
            {errors?.wish?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Sponsorship Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Sponsorship Information</h4>
        </Alert>

        {/* Sponsor ID */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          {data?.sponsors.length > 0 ? (
            <>
              <Form.Label>Sponsored By</Form.Label>
              <Controller
                name='selectedSponsor'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    {...field}
                    isSearchable={true}
                    isClearable={true}
                    options={sponsorOptions}
                    placeholder='Select a Sponsor'
                  />
                )}
              />

              <p className='text-danger small'>
                {errors?.selectedSponsor?.message ||
                  errors.selectedSponsor?.value.message}
              </p>
              <p className='text-danger'>
                Warning: Changing this Angel's sponsor will replace the previous
                sponsor unless this Angel has not been sponsored. Choose "None"
                if you want to remove this Angel's sponsor.
              </p>
            </>
          ) : (
            <>
              <Alert variant='warning'>
                There are no sponsors available. Please add a new Sponsor before
                sponsoring this Angel.
              </Alert>
            </>
          )}
        </Form.Group>

        <div className='d-grid gap-2'>
          <Button variant='outline-secondary' type='reset' size='lg'>
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='success' type='submit' size='lg' disabled={loading}>
            {updateLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Save Changes <IoSave />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditChild;
