import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import {
  IoAddCircleOutline,
  IoTrashOutline,
  IoAddSharp,
  IoSend,
  IoArrowBack,
} from 'react-icons/io5';
import { MdOutlineFaceRetouchingNatural } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';

// Import React-Hook-Forms and yup
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import parent actions
import { submitParentFormToServer } from '../parentFormSlice';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('Full Name Required'),
  studentId: yup
    .string()
    .required('Student ID required')
    .matches(/^[1-3][0-9][0-9]\d+$\S*$/, 'Invalid Student ID')
    .min(9, 'Student Id must be 9 digits')
    .max(9, 'Student Id must be 9 digits'),
  email: yup
    .string()
    .email('Incorrect Email Format')
    .required('Email Required'),
  pickupLocation: yup.string().required('Pick up location required'),
  additionalPerson: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number'),
  phone: yup
    .string()
    .required('Phone number required')
    .min(14, 'Phone number must have 10 digits')
    .max(14, 'Phone number must have 10 digits'),

  children: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .matches(/^(\D*)$/, 'Name should not container number')
          .required('First Name Required'),
        age: yup.string().required('Please select age'),
        gender: yup.string().required('Please select gender'),
        wish: yup.string().required('Wish required'),
      })
    )
    .max(5, 'Hey we run out of the alphabet'),
});

const NewParentForm = () => {
  const navigate = useNavigate();
  const parentForm = useSelector((state) => state.parent.parentForm);
  const dispatch = useDispatch();

  const { loading, error } = parentForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      name: '',
      studentId: '',
      email: '',
      phone: '',
      pickupLocation: '',
      additionalPerson: '',
      approved: '',
      sendEmail: '',
      children: [{ name: '', age: '', gender: '', wish: '' }],
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'children',
  });

  const childrenArray = watch('children');
  const approved = watch('approved');

  useEffect(() => {
    if (childrenArray.length === 26) {
      toast.error('Hey, we ran out of the letters in Alphabet :).');
    }
  }, [childrenArray]);

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');
    if (!phoneNumber) return value;
    return phoneNumber.formatNational();
  };

  const onSubmit = (data) => {
    if (window.confirm('Do you really want to add this new Parent?')) {
      dispatch(submitParentFormToServer(data))
      .unwrap()
        .then((message) => {
          reset();
          toast.success(message);
          navigate('/parent');
        })
        .catch((err) => {
          toast.error(err);
          reset();
        });
    }
  };

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <IoAddCircleOutline size={50} />
          </span>{' '}
          Add New Parent
        </h1>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Parent Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Parent's Information</h4>
        </Alert>

        {/* Full Name */}
        <Form.Group className='mb-3' controlId='formBasicName'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type='text'
            {...register('name')}
            placeholder='Enter Full Name'
            isValid={dirtyFields?.name && !errors?.name}
            isInvalid={errors?.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Student ID */}
        <Form.Group className='mb-3' controlId='formBasicStudentID'>
          <Form.Label>UFV Student ID</Form.Label>
          <Form.Control
            type='text'
            {...register('studentId')}
            placeholder='Enter Student ID'
            isValid={dirtyFields?.studentId && !errors?.studentId}
            isInvalid={errors?.studentId}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.studentId?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email Address */}
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            {...register('email')}
            isValid={dirtyFields?.email && !errors?.email}
            placeholder='Enter Email'
            isInvalid={errors?.email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Phone Number (using Controller for formatting the phone number) */}
        <Form.Group className='mb-3' controlId='formBasicPhone'>
          <Form.Label>Phone Number</Form.Label>
          <Controller
            name='phone'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Control
                {...field}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(event) =>
                  field.onChange(normalizePhoneNumber(event.target.value))
                }
                isValid={dirtyFields?.phone && !errors?.phone}
                isInvalid={errors?.phone}
              />
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Pickup Location */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>Pickup Location</Form.Label>
          <Controller
            name='pickupLocation'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={dirtyFields?.pickupLocation && !errors?.pickupLocation}
                isInvalid={errors?.pickupLocation}
              >
                <option value=''>Please select a pickup location</option>
                <option value='Abbotsford'>
                  Abbotsford: Abby Campus
                </option>
                <option value='Chilliwack'>
                  Chilliwack: CEP Campus
                </option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.pickupLocation?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Additional Person to pickup the wish */}
        <Form.Group className='mb-3' controlId='formAdditionalPerson'>
          <Form.Label>Additional Person (optional)</Form.Label>
          <Form.Control
            type='text'
            {...register('additionalPerson')}
            placeholder='Enter Additional Person'
          />
        </Form.Group>

        {/* Approved Status. False by default */}
        <Form.Group className='mb-3' controlId='formApproved'>
          <Form.Label className='me-3'>Approve This Application?</Form.Label>
          <Form.Check
            inline
            type='radio'
            name='approveGroup'
            id='inline-radio-1'
            value={1}
            label='Yes'
            {...register('approved')}
          />
          <Form.Check
            inline
            type='radio'
            name='approveGroup'
            id='inline-radio-2'
            value={0}
            label='No'
            {...register('approved')}
            defaultChecked
          />
        </Form.Group>

        {/* Send Confirmation email? */}
        {approved === '1' ? (
          <Form.Group className='mb-3' controlId='formApproved'>
            <Form.Label className='me-3'>Send Confirmation Email?</Form.Label>
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-1'
              value={1}
              label='Yes'
              {...register('sendEmail')}
            />
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-2'
              value={0}
              label='No'
              {...register('sendEmail')}
              defaultChecked
            />
          </Form.Group>
        ) : (
          <></>
        )}

        {/* Children Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Angel's Information</h4>
        </Alert>

        {fields.map((item, index) => (
          <div key={item.id}>
            {/* Every parent must have at least 1 child by default */}
            {index > 0 ? (
              <div>
                <h5>
                  <MdOutlineFaceRetouchingNatural size={30} /> Angel {index + 1}{' '}
                  <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => remove(index)}
                  >
                    <IoTrashOutline />{' '}
                  </Button>
                </h5>
              </div>
            ) : (
              <div>
                <h5>
                  <MdOutlineFaceRetouchingNatural size={30} /> Angel {index + 1}{' '}
                </h5>
              </div>
            )}

            {/* First Name field */}
            <Form.Group className='mb-3' controlId='formBasicName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                {...register(`children.${index}.name`)}
                placeholder='Enter First Name'
                isValid={
                  dirtyFields?.children?.[index]?.name &&
                  !errors?.children?.[index]?.name
                }
                isInvalid={errors?.children?.[index]?.name?.message}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Age */}
            <Form.Group className='mb-3' controlId={`children.${index}.age`}>
              <Form.Label>Angel Age</Form.Label>
              <Controller
                name={`children.${index}.age`}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    aria-label='Default select age'
                    id={`children.${index}.age`}
                    isValid={
                      dirtyFields?.children?.[index]?.age &&
                      !errors?.children?.[index]?.age
                    }
                    isInvalid={errors?.children?.[index]?.age}
                  >
                    <option value=''> Please select age</option>
                    {Array(17)
                      .fill()
                      .map((_, i) => (
                        <option key={i} value={Number(i)}>
                          {Number(i)}
                        </option>
                      ))}
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.age?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Gender */}
            <Form.Group className='mb-3' controlId={`children.${index}.gender`}>
              <Form.Label>Angel Gender</Form.Label>
              <Controller
                name={`children.${index}.gender`}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    aria-label='Default select gender'
                    id={`children.${index}.gender`}
                    isValid={
                      dirtyFields?.children?.[index]?.gender &&
                      !errors?.children?.[index]?.gender
                    }
                    isInvalid={errors?.children?.[index]?.gender}
                  >
                    <option value=''> Please select a gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.gender?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Wish */}
            <Form.Group className='mb-3' controlId={`children.${index}.wish`}>
              <Form.Label>Angel Wish</Form.Label>
              <Form.Control
                as='textarea'
                wrap='hard'
                rows={3}
                col={25}
                placeholder='Kindi Kids Doll, Creative Blue Bricks'
                {...register(`children.${index}.wish`)}
                isValid={
                  dirtyFields?.children?.[index]?.wish &&
                  !errors?.children?.[index]?.wish
                }
                isInvalid={errors?.children?.[index]?.wish}
              />
              <p className='text-muted mt-2'>
                Tip: When submitting multiple wishes, please use a comma(,) to
                separate the wishes like the example above.
              </p>
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.wish?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        ))}

        <div className='mb-3'>
          <Button
            variant='outline-success'
            size='sm'
            disabled={childrenArray.length >= 26 ? true : false}
            onClick={() => append({ name: '', age: '', gender: '', wish: '' })}
          >
            <IoAddSharp size={25} /> More Angel
          </Button>
        </div>

        <div className='d-grid gap-2'>
          <Button variant='outline-secondary' type='reset' size='lg'>
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='primary' type='submit' size='lg' disabled={loading}>
            {loading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Submit Form <IoSend />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewParentForm;
