import React, { useReducer } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';

import { FaUnlock } from 'react-icons/fa';
import api from '../../services/api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const initialState = {
  passwd: '',
  passwd2: '',
  requestLoading: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_PASSWORD':
      return {
        ...state,
        [payload.name]: payload.value,
      };
    case 'SEND_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        requestLoading: true,
      };
    case 'SEND_RESET_PASSWORD_SUCCESS':
    case 'SEND_REQUEST_PASSWORD_FAIL':
      return {
        ...state,
        requestLoading: false,
      };
    default:
      return state;
  }
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { passwd, passwd2, requestLoading } = state;

  const onSubmit = (e) => {
    e.preventDefault();

    let abortController = new AbortController();
    let res;

    if (passwd !== passwd2) {
      toast.error('Passwords do not match');
      return;
    }

    dispatch({ type: 'SEND_RESET_PASSWORD_REQUEST' });
    setTimeout(async () => {
      try {
        res = await api.put(
          `/auth/resetpassword?${searchParams.toString()}`,
          { password: passwd },
          { signal: abortController.signal }
        );
        dispatch({ type: 'SEND_RESET_PASSWORD_SUCCESS' });
        toast.success(res.data.message);
        navigate('/auth/login', { replace: true });
      } catch (error) {
        dispatch({
          type: 'SEND_REQUEST_PASSWORD_FAIL',
          payload: error.response?.data?.message || 'Something went wrong',
        });
        toast.error(error.response?.data?.message || 'Something went wrong');
        navigate('/auth/forgotpassword', { replace: true });
      }
    }, 2000);
  };

  return (
    <Container className='mt-5 p-5' fluid='md'>
      <Card>
        <Card.Body>
          <Card.Title className='text-center'>
            <FaUnlock size={100} />
            <h2 className='mt-3'>Reset Password</h2>
          </Card.Title>
          <>
            <Form className='w-100 mt-3' onSubmit={(e) => onSubmit(e)}>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <div className='d-flex align-items-center justify-content-center'>
                  <Form.Label className='w-75'>New Password</Form.Label>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <Form.Control
                    type='password'
                    name='passwd'
                    required
                    placeholder='New password'
                    value={passwd}
                    onChange={(e) =>
                      dispatch({ type: 'SET_PASSWORD', payload: e.target })
                    }
                    className='w-75'
                  />
                </div>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword2'>
                <div className='d-flex align-items-center justify-content-center'>
                  <Form.Label className='w-75'>Email Address</Form.Label>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <Form.Control
                    type='password'
                    name='passwd2'
                    required
                    placeholder='Confirm new password'
                    value={passwd2}
                    onChange={(e) =>
                      dispatch({ type: 'SET_PASSWORD', payload: e.target })
                    }
                    className='w-75'
                  />
                </div>
              </Form.Group>

              <div className='text-center p-3'>
                <Button
                  variant='primary'
                  type='submit'
                  size='lg'
                  disabled={requestLoading}
                >
                  {requestLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </Form>
            <div className='text-center p-3'>
              <a href='/auth/login'>Login now</a>
            </div>
            <div className='text-center p-3'>
              Not a member? <a href='/auth/register'>Register now</a>
            </div>
          </>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResetPasswordPage;
