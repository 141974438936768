import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const fetchStatInfo = createAsyncThunk('home/fetchStatInfo', async () => {
  try {
    const [res1, res2, res3] = await Promise.all([
      api.get('/stat/parent'),
      api.get('/stat/child'),
      api.get('/stat/sponsor'),
    ]);

    const data = convertStatNames({
      parent: res1.data.data,
      child: res2.data.data,
      sponsor: res3.data.data,
    });

    return data;
  } catch (error) {
    throw error.response?.data?.message || 'Error occurred while fetching data';
  }
});


const convertStatNames = (data) => {
  return {
    totalRegisteredParents: data.parent.totalRegisteredParents,
    approvedParents: data.parent.approvedParents,
    unapprovedParents: data.parent.unapprovedParents,
    totalChildren: data.child.totalChildren,
    approvedChildren: data.child.approvedChildren,
    unapprovedChildren: data.child.unapprovedChildren,
    sponsoredChildren:data.child.sponsoredChildren,
    totalSponsors: data.sponsor.totalSponsors,
    parentLocations: [
      { name: 'Abbotsford Parents', value: data.parent.abbotsfordParents },
      { name: 'Chilliwack Parents', value: data.parent.chilliwackParents },
    ],
    parentApprovalStatus: [
      { name: 'Approved Parents', value: data.parent.approvedParents },
      { name: 'Unapproved Parents', value: data.parent.unapprovedParents },
    ],
    approvedParentLocations: [
      { name: 'Abbotsford Parents', value: data.parent.abbotsfordApprovedParents },
      { name: 'Chilliwack Parents', value: data.parent.chilliwackApprovedParents },
    ],
    pickup: [
      { name: 'Picked Up', value: data.parent.pickedUp },
      { name: 'Not Picked Up', value: data.parent.notPickedUp },
    ],
    childAgeGroup: ageGroupArray(data),
    childGenders: [
      { name: 'Male', value: data.child.numberOfMaleChildren },
      { name: 'Female', value: data.child.numberOfFemaleChildren },
      { name: 'Other', value: data.child.numberOfOtherChildren },
    ],
    childApprovalStatus: [
      { name: 'Approved Angels', value: data.child.approvedChildren },
      { name: 'Unapproved Angels', value: data.child.unapprovedChildren },
    ],
    childSponsorship: [
      { name: 'Sponsored Angels', value: data.child.sponsoredChildren },
      { name: 'Unsponsored Angels', value: data.child.unSponsoredChildren },
    ],
    sponsor: [
      { name: 'Abbotsford Sponsor', value: data.sponsor.abbotsfordSponsor },
      { name: 'Chilliwack Sponsor', value: data.sponsor.chilliwackSponsor },
    ],
    dropoff: [
      { name: 'Dropped Off', value: data.sponsor.droppedOff },
      { name: 'Not Dropped Off', value: data.sponsor.notDroppedOff },
    ],
  };
};

const ageGroupArray = (data) => {
  const ageGroup = [];

  if (data?.child !== null) {
    for(let age = 0; age <= 16; age++) {
      const group = {
        dataKeyX: `Age ${age}`,
        'Number of Angels': data.child[`numberOfAge${age}`]
      };

      ageGroup.push(group);
    }

    return ageGroup;
  }

  return ageGroup;
} 

const statInfoSlice = createSlice({
  name: 'statInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchStatInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // action.error.message contains the error message
      });
  },
});

export default statInfoSlice.reducer;
