import React, { useReducer } from 'react';
import { Button, Card, Container, Form, Spinner, Alert } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';

import api from '../../services/api';

const initialState = {
  email: '',
  requestLoading: false,
  isSent: false,
  errorMessage: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_EMAIL':
      return {
        ...state,
        email: payload,
      };
    case 'SEND_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        requestLoading: true,
      };
    case 'SEND_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        requestLoading: false,
        isSent: true,
      };
    case 'SEND_REQUEST_PASSWORD_FAIL':
      return {
        ...state,
        requestLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

const ForgetPassordPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { email, requestLoading, isSent, errorMessage } = state;

  const onSubmit = (e) => {
    e.preventDefault();
    let abortController = new AbortController();

    dispatch({ type: 'SEND_RESET_PASSWORD_REQUEST' });
    setTimeout(async () => {
      try {
        await api.post(
          '/auth/forgotpassword',
          {
            email,
            role: 'admin',
          },
          { signal: abortController.signal }
        );
        dispatch({ type: 'SEND_RESET_PASSWORD_SUCCESS' });
        dispatch({ type: 'SET_EMAIL', payload: '' });
      } catch (error) {
        dispatch({
          type: 'SEND_REQUEST_PASSWORD_FAIL',
          payload: error.response?.data?.message || 'Something went wrong',
        });
      }
    }, 2000);
  };

  return (
    <Container className='mt-5 p-5' fluid='md'>
      {errorMessage !== '' && <Alert variant='danger'>{errorMessage}</Alert>}
      <Card>
        <Card.Body>
          <Card.Title className='text-center'>
            <FaLock size={100} />
            <h2 className='mt-3'>Forgot Password</h2>
          </Card.Title>
          <>
            {isSent ? (
              <Card.Text className='text-center mt-3' as='div'>
                <p style={{ fontSize: '22px' }}>
                  An email has been sent to your mailbox for resetting the
                  password!
                </p>
              </Card.Text>
            ) : (
              <Form className='w-100 mt-3' onSubmit={(e) => onSubmit(e)}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Form.Label className='w-75'>Email Address</Form.Label>
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Form.Control
                      type='email'
                      name='email'
                      required
                      placeholder='UFV SUS Email'
                      value={email}
                      onChange={(e) =>
                        dispatch({ type: 'SET_EMAIL', payload: e.target.value })
                      }
                      className='w-75'
                    />
                  </div>
                </Form.Group>

                <div className='text-center p-3'>
                  <Button
                    variant='primary'
                    type='submit'
                    size='lg'
                    disabled={requestLoading}
                  >
                    {requestLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </div>
              </Form>
            )}
            <div className='text-center p-3'>
              <a href='/auth/login'>Login now</a>
            </div>
            <div className='text-center p-3'>
              Not a member? <a href='/auth/register'>Register now</a>
            </div>
          </>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ForgetPassordPage;
