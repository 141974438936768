import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

import { VscDebugRestart } from 'react-icons/vsc';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

import { useSelector, useDispatch } from 'react-redux';
// Import parent actions
import { getSingleParentInfo, updateSingleParentInfo } from '../parentFormSlice';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('Full Name Required'),
  studentId: yup
    .string()
    .required('Student ID required')
    .matches(/^[1-3][0-9][0-9]\d+$\S*$/, 'Invalid Student ID')
    .min(9, 'Student Id must be 9 digits')
    .max(9, 'Student Id must be 9 digits'),
  email: yup
    .string()
    .email('Incorrect Email Format')
    .required('Email Required'),
  pickupLocation: yup.string().required('Pick up location required'),
  additionalPerson: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number'),
  phone: yup
    .string()
    .required('Phone number required')
    .min(14, 'Phone number must have 10 digits')
    .max(14, 'Phone number must have 10 digits'),
});

const EditParent = () => {
  let params = useParams();
  const navigate = useNavigate();

  const parentForm = useSelector((state) => state.parent.parentForm);
  const dispatch = useDispatch();

  const { loading, updateLoading, error, data } = parentForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      name: data?.parentName || '',
      studentId: data?.studentId || '',
      email: data?.email || '',
      phone: data?.phone || '',
      pickupLocation: data?.pickupLocation || '',
      additionalPerson:
        data?.additionalPerson === 'None' ? '' : data?.additionalPerson,
      approved: data?.approved === 'Yes' ? '1' : '0',
      pickup: data?.pickup === 'Yes' ? '1' : '0',
      sendEmail: 0,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getSingleParentInfo(params.userId))
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch, params.userId]);

  useEffect(() => {
    if (data)
      reset({
        name: data?.parentName,
        studentId: data?.studentId,
        email: data?.email,
        phone: data?.phone,
        pickupLocation: data?.pickupLocation,
        additionalPerson:
          data?.additionalPerson === 'None' ? '' : data?.additionalPerson,
        approved: data?.approved === 'Yes' ? '1' : '0',
        pickup: data?.pickup === 'Yes' ? '1' : '0',
      });
  }, [data, reset]);

  const approved = watch('approved');
  const pickup = watch('pickup');

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');
    if (!phoneNumber) return value;
    return phoneNumber.formatNational();
  };

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to update this Parent?')) {
      const formData = {
        ...values,
        parentId: data.parentId,
        pickupId: data.pickupId,
      };
      dispatch(updateSingleParentInfo(formData))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          navigate('/parent', { replace: true });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (loading)
    return (
      <Container className='mt-5'>
        <div>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Button
            variant='outline-secondary'
            className='mb-3'
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoArrowBack /> Go Back
          </Button>
          <h1>
            <span>
              <FiEdit size={50} />
            </span>{' '}
            Edit Parent
          </h1>
        </div>

        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{ height: '50vh' }}
        >
          <Spinner animation='border' role='status' variant='success'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </Container>
    );

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <FiEdit size={50} />
          </span>{' '}
          Edit Parent{' '}
          {data && <span className='text-danger'>ID {data.parentId}</span>}
        </h1>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Parent Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Parent's Information</h4>
        </Alert>

        {/* Full Name */}
        <Form.Group className='mb-3' controlId='formBasicName'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type='text'
            {...register('name')}
            placeholder='Enter Full Name'
            isValid={dirtyFields?.name && !errors?.name}
            isInvalid={errors?.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Student ID */}
        <Form.Group className='mb-3' controlId='formBasicStudentID'>
          <Form.Label>UFV Student ID</Form.Label>
          <Form.Control
            type='text'
            {...register('studentId')}
            placeholder='Enter Student ID'
            isValid={dirtyFields?.studentId && !errors?.studentId}
            isInvalid={errors?.studentId}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.studentId?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email Address */}
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            {...register('email')}
            isValid={dirtyFields?.email && !errors?.email}
            placeholder='Enter Email'
            isInvalid={errors?.email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Phone Number (using Controller for formatting the phone number) */}
        <Form.Group className='mb-3' controlId='formBasicPhone'>
          <Form.Label>Phone Number</Form.Label>
          <Controller
            name='phone'
            control={control}
            render={({ field }) => (
              <Form.Control
                {...field}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(event) =>
                  field.onChange(normalizePhoneNumber(event.target.value))
                }
                isValid={dirtyFields?.phone && !errors?.phone}
                isInvalid={errors?.phone}
              />
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Pickup Location */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>Pickup Location</Form.Label>
          <Controller
            name='pickupLocation'
            control={control}
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={dirtyFields?.pickupLocation && !errors?.pickupLocation}
                isInvalid={errors?.pickupLocation}
              >
                <option value=''>Please select a pickup location</option>
                <option value='Abbotsford'>
                  Abbotsford: Abby Campus
                </option>
                <option value='Chilliwack'>
                  Chilliwack: CEP Campus
                </option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.pickupLocation?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Additional Person to pickup the wish */}
        <Form.Group className='mb-3' controlId='formAdditionalPerson'>
          <Form.Label>Additional Person (optional)</Form.Label>
          <Form.Control
            type='text'
            {...register('additionalPerson')}
            placeholder='Enter Additional Person'
          />
        </Form.Group>

        {/* Approved Status */}
        <Form.Group className='mb-3' controlId='formApproved'>
          <Form.Label className='me-3'>Approve This Application?</Form.Label>
          <Form.Check
            inline
            type='radio'
            name='approveGroup'
            id='inline-radio-1'
            value={1}
            label='Yes'
            defaultChecked={data && data?.approved === 'Yes'}
            {...register('approved')}
          />
          <Form.Check
            inline
            type='radio'
            name='approveGroup'
            id='inline-radio-2'
            value={0}
            label='No'
            defaultChecked={data && data?.approved === 'No'}
            {...register('approved')}
          />
        </Form.Group>

        {/* Pickup Status */}
        {approved === '1' ? (
          <Form.Group className='mb-3' controlId='formApproved'>
            <Form.Label className='me-3'>Picked Up Angel Wish?</Form.Label>
            <Form.Check
              inline
              type='radio'
              name='pickupGroup'
              id='inline-radio-pickup'
              value={1}
              label='Yes'
              defaultChecked={data && data?.pickup === 'Yes'}
              {...register('pickup')}
            />
            <Form.Check
              inline
              type='radio'
              name='pickupGroup'
              id='inline-radio-pickup'
              value={0}
              label='No'
              defaultChecked={data && data?.pickup === 'No'}
              {...register('pickup')}
            />
          </Form.Group>
        ) : (
          <></>
        )}

        {/* Send Confirmation email? */}
        {approved === '1' && pickup === '1' ? (
          <Form.Group className='mb-3' controlId='formApproved'>
            <Form.Label className='me-3'>
              Send Pickup Confirmation Email?
            </Form.Label>
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-1'
              value={1}
              label='Yes'
              {...register('sendEmail')}
            />
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-2'
              value={0}
              label='No'
              {...register('sendEmail')}
              defaultChecked
            />
          </Form.Group>
        ) : (
          <></>
        )}

        {/* Children Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Angel's Information</h4>
        </Alert>

        <div className='mb-3'>
          <p className='lead'>
            This parent/caregiver has{' '}
            <span className='text-danger'>{data?.numberOfAngels}</span>{' '}
            Angel(s).
          </p>
          <p className='small text-danger'>
            * For editing Angel's information of this parent, please visit the{' '}
            <a className='text-danger' href='/child' target='_blank'>
              Angel Page
            </a>{' '}
            and select the Angel whose Parent ID is {data?.parentId}.
          </p>
        </div>

        <div className='d-grid gap-2'>
          <Button variant='outline-secondary' type='reset' size='lg'>
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='success' type='submit' size='lg' disabled={loading}>
            {updateLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Save Changes <IoSave />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditParent;
