import { format } from 'date-fns';

export const COLUMNS = [
  {
    Header: 'Parent Information',
    columns: [
      {
        Header: 'Parent ID',
        accessor: 'parentId',
      },
      {
        Header: 'Parent Name',
        accessor: 'parentName',
      },
      {
        Header: 'Number of Angels',
        accessor: 'numberOfAngels',
      },
      {
        Header: 'Student ID',
        accessor: 'studentId',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Approved Status',
        accessor: 'approved',
      },
      {
        Header: 'SMS Update',
        accessor: 'smsUpdate'
      }
    ],
  },
  {
    Header: 'Pick-up Information',
    columns: [
      {
        Header: 'Confirmation ID',
        accessor: 'pickupId',
      },
      {
        Header: 'Pickup Location',
        accessor: 'pickupLocation',
      },
      {
        Header: 'Additional Person',
        accessor: 'additionalPerson',
      },
      {
        Header: 'Picked Up?',
        accessor: 'pickup',
      },
      {
        Header: 'Registration Time',
        accessor: 'registrationTime',
        Cell: ({ value }) => {
          return format(new Date(value), 'MMM d, yyyy H:mm');
        },
      },
      {
        Header: 'Pickup Time',
        accessor: 'pickupTime',
        Cell: ({ value }) => {
          return value && format(new Date(value), 'MMM d, yyyy H:mm');
        },
      },
    ],
  },
];
