import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutAccount } from '../../auth/authSlice';

const PasswordChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutAccount())
      .unwrap()
      .then(() => navigate('/auth/forgotpassword'))
      .catch((err) => toast.error(err));
  };

  return (
    <div className='mt-5'>
      <Alert variant='info'>
        <h4>Change Password</h4>
      </Alert>
      <p className='lead'>
        To change your password, click{' '}
        <Button variant='warning' size='sm' onClick={() => handleLogout()}>
          here
        </Button>{' '}
        to reset the password. Please be aware that you will be logged out.
      </p>
    </div>
  );
};

export default PasswordChange;
