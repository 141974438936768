import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialParentFormState = {
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    success: false,
    error: null,
    updateError: null,
    deleteError: null,
    data: null,
    selectedParents: [],
};

export const submitParentFormToServer = createAsyncThunk('parentForm/submitParentFormToServer', async (data, { rejectWithValue }) => {
    const formData = {
        name: data.name,
        email: data.email,
        studentId: data.studentId,
        phone: data.phone,
        approved: data.approved,
        sendEmail: data.sendEmail,
        pickup: {
            pickupLocation: data.pickupLocation,
            additionalPerson: data?.additionalPerson,
        },
        children: data.children,
    };

    try {
        const res = await api.post('/parent/admin-parent', formData);

        return res.data?.message;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Parent form could not be submitted');
    }
});

// Get single parent info
export const getSingleParentInfo = createAsyncThunk('parentForm/getSingleParentInfo', async (userId, { rejectWithValue }) => {
    try {
        const res = await api.get(`/parent?userId=${userId}`);

        return res?.data?.data[0];
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not fetch this parent');
    }
});

// Update a single parent
export const updateSingleParentInfo = createAsyncThunk('parentForm/updateSingleParentInfo', async (data, { rejectWithValue }) => {
    const parentInfo = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        studentId: data.studentId,
        approved: data.approved,
    };

    const pickupInfo = {
        pickupLocation: data.pickupLocation,
        additionalPerson:
            data?.additionalPerson === '' ? 'None' : data?.additionalPerson,
        pickup: data.pickup,
        sendEmail: data.sendEmail,
    };

    try {
        await Promise.all(
            [
                api.put(`/parent/${data.parentId}`, parentInfo),
                api.put(`/parent/pickup/${data.pickupId}`, pickupInfo)
            ]);

        return `Parent ID ${data.parentId} successfully updated`;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update parent information');
    }
});

// Update approved status of many parents
export const updateParentsApprovedStatus = createAsyncThunk('parentForm/updateParentsApprovedStatus', async (newUpdatedParents, { rejectWithValue }) => {
    try {
        const res = await api.patch('/parent/approved-status', { parents: newUpdatedParents });

        return res.data?.message || `Approved status of ${newUpdatedParents.length} parents successfully updated.`
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update approved status of these parents');
    }
});

// Update the pickup status of many parents
export const updateParentsPickupStatus = createAsyncThunk('parentForm/updateParentsPickupStatus', async (newUpdatedParents, { rejectWithValue }) => {
    try {
        const res = await api.patch('/parent/pickup/pickup-status', { parents: newUpdatedParents });

        return res.data?.message || `Pickup statuses of ${newUpdatedParents.length} parents successfully updated.`;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update pickup status of these parents');
    }
});

// Delete a single parent
export const deleteSingleParent = createAsyncThunk('parentForm/deleteSingleParent', async (userId, { rejectWithValue }) => {
    try {
        const res = await api.delete(`/parent/${userId}`);

        return res.data.message || 'Parent successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'This Parent could not be deleted');
    }
});

// Delete many parents
export const deleteManyParents = createAsyncThunk('parentForm/deleteManyParents', async (userIds, { rejectWithValue }) => {
    try {
        const res = await api.patch('/parent', { userIds });

        return res.data.message || 'Parents successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'Parents could not be deleted');
    }
});

const parentFormSlice = createSlice({
    name: 'parentForm',
    initialState: initialParentFormState,
    reducers: {
        // Select many parents for bulk update
        selectManyParents: (state, action) => {
            state.selectedParents = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitParentFormToServer.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitParentFormToServer.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(submitParentFormToServer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getSingleParentInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleParentInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getSingleParentInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSingleParentInfo.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateSingleParentInfo.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateSingleParentInfo.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(updateParentsApprovedStatus.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateParentsApprovedStatus.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateParentsApprovedStatus.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(updateParentsPickupStatus.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateParentsPickupStatus.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateParentsPickupStatus.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(deleteSingleParent.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteSingleParent.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteSingleParent.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
            .addCase(deleteManyParents.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteManyParents.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteManyParents.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
    }
});

export const { selectManyParents } = parentFormSlice.actions;
export default parentFormSlice.reducer;