import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialParentInfoState = {
    loading: false,
    error: null,
    data: [],
};

export const fetchParentInfo = createAsyncThunk('parentInfo/fetchParentInfo', async (_, {rejectWithValue}) => {
    try {
        const res = await api.get('/parent');

        return res.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message);
    }
});

const parentInfoSlice = createSlice({
    name: 'parentInfo',
    initialState: initialParentInfoState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchParentInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchParentInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchParentInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export default parentInfoSlice.reducer;