import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import {
  IoAddCircleOutline,
  IoTrashOutline,
  IoAddSharp,
  IoSend,
  IoArrowBack,
} from 'react-icons/io5';
import { MdOutlineFaceRetouchingNatural } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';

// Import React-Hook-Forms and yup
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import child, parent actions
import { submitChildFormToServer } from '../childFormSlice';
import { fetchParentInfo } from '../../parent/parentInfoSlice';

const schema = yup.object().shape({
  // parentId: yup.string().required('Please select the Parent for this Angel'),
  selectedParent: yup
    .object()
    .shape({
      label: yup.string().required('Please select a parent'),
      value: yup.string().required('Please select a parent'),
    })
    .nullable()
    .required('Please select a parent'),
  children: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .matches(/^(\D*)$/, 'Name should not container number')
          .required('First Name Required'),
        age: yup.string().required('Please select age'),
        gender: yup.string().required('Please select gender'),
        wish: yup.string().required('Wish required'),
      })
    )
    .max(5, 'Hey we run out of the alphabet'),
});

const NewChildForm = (props) => {
  const navigate = useNavigate();
  const childForm = useSelector((state) => state.child.childForm);
  const parentInfo = useSelector((state) => state.parent.parentInfo);

  const dispatch = useDispatch();

  const { loading, error } = childForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      selectedParent: {
        value: '',
        label: '',
      },
      children: [{ name: '', age: '', gender: '', wish: '' }],
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'children',
  });

  const childrenArray = watch('children');

  useEffect(() => {
    dispatch(fetchParentInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch]);

  useEffect(() => {
    if (childrenArray.length === 26) {
      toast.error('Hey, we ran out of the letters in Alphabet :).');
    }
  }, [childrenArray]);

  const parentOptions = useMemo(() => {
    if (parentInfo.data.length === 0) return [];

    const parentArray = parentInfo.data.map((parent) => {
      return {
        value: parent.parentId,
        label: `${parent.parentName}, ${parent.email}`,
      };
    });

    return parentArray.sort((a, b) => a.label.localeCompare(b.label)); //sort by parentName
  }, [parentInfo.data]);

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to add new Angel(s)?')) {
      const data = {
        children: values.children,
        parentId: values.selectedParent.value,
      };

      dispatch(submitChildFormToServer(data))
        .unwrap()
        .then((message) => {
          reset();
          toast.success(message);
          navigate('/child');
        })
        .catch((err) => {
          toast.error(err);
          reset();
        });
    }
  };

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <IoAddCircleOutline size={50} />
          </span>{' '}
          Add New Angel(s)
        </h1>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Parent Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Parent's Information</h4>
        </Alert>

        {/* Parent ID */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          {parentInfo.data.length > 0 ? (
            <>
              <Form.Label>Parent of this Angel</Form.Label>
              <Controller
                name='selectedParent'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isSearchable={true}
                    isClearable={true}
                    options={parentOptions}
                    placeholder='Select a Parent'
                  />
                )}
              />
            </>
          ) : (
            <>
              <Alert variant='warning'>
                There are no Parents available. Please add a new Parent before
                adding Angels
              </Alert>
            </>
          )}

          <p className='text-danger small'>
            {errors?.selectedParent?.message ||
              errors.selectedParent?.value.message}
          </p>
        </Form.Group>

        {/* Children Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Angel's Information</h4>
        </Alert>

        {fields.map((item, index) => (
          <div key={item.id}>
            {/* Every parent must have at least 1 child by default */}
            {index > 0 ? (
              <div>
                <h5>
                  <MdOutlineFaceRetouchingNatural size={30} /> Angel {index + 1}{' '}
                  <Button
                    variant='outline-danger'
                    size='sm'
                    onClick={() => remove(index)}
                  >
                    <IoTrashOutline />{' '}
                  </Button>
                </h5>
              </div>
            ) : (
              <div>
                <h5>
                  <MdOutlineFaceRetouchingNatural size={30} /> Angel {index + 1}{' '}
                </h5>
              </div>
            )}

            {/* First Name field */}
            <Form.Group className='mb-3' controlId='formBasicName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                {...register(`children.${index}.name`)}
                placeholder='Enter First Name'
                isValid={
                  dirtyFields?.children?.[index]?.name &&
                  !errors?.children?.[index]?.name
                }
                isInvalid={errors?.children?.[index]?.name?.message}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Age */}
            <Form.Group className='mb-3' controlId={`children.${index}.age`}>
              <Form.Label>Angel Age</Form.Label>
              <Controller
                name={`children.${index}.age`}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    aria-label='Default select age'
                    id={`children.${index}.age`}
                    isValid={
                      dirtyFields?.children?.[index]?.age &&
                      !errors?.children?.[index]?.age
                    }
                    isInvalid={errors?.children?.[index]?.age}
                  >
                    <option value=''> Please select age</option>
                    {Array(17)
                      .fill()
                      .map((_, i) => (
                        <option key={i} value={Number(i)}>
                          {Number(i)}
                        </option>
                      ))}
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.age?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Gender */}
            <Form.Group className='mb-3' controlId={`children.${index}.gender`}>
              <Form.Label>Angel Gender</Form.Label>
              <Controller
                name={`children.${index}.gender`}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    aria-label='Default select gender'
                    id={`children.${index}.gender`}
                    isValid={
                      dirtyFields?.children?.[index]?.gender &&
                      !errors?.children?.[index]?.gender
                    }
                    isInvalid={errors?.children?.[index]?.gender}
                  >
                    <option value=''> Please select a gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.gender?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Child Wish */}
            <Form.Group className='mb-3' controlId={`children.${index}.wish`}>
              <Form.Label>Angel Wish</Form.Label>
              <Form.Control
                as='textarea'
                wrap='hard'
                rows={3}
                col={25}
                placeholder='Kindi Kids Doll, Creative Blue Bricks'
                {...register(`children.${index}.wish`)}
                isValid={
                  dirtyFields?.children?.[index]?.wish &&
                  !errors?.children?.[index]?.wish
                }
                isInvalid={errors?.children?.[index]?.wish}
              />
              <p className='text-muted mt-2'>
                Tip: When submitting multiple wishes, please use a comma(,) to
                separate the wishes like the example above.
              </p>
              <Form.Control.Feedback type='invalid'>
                {errors?.children?.[index]?.wish?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        ))}

        <div className='mb-3'>
          <Button
            variant='outline-success'
            size='sm'
            disabled={childrenArray.length >= 26 ? true : false}
            onClick={() => append({ name: '', age: '', gender: '', wish: '' })}
          >
            <IoAddSharp size={25} /> More Angel
          </Button>
        </div>

        <div className='d-grid gap-2'>
          <Button variant='outline-secondary' type='reset' size='lg'>
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='primary' type='submit' size='lg' disabled={loading}>
            {loading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Submit Form <IoSend />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewChildForm;
