import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { RiEdit2Fill } from 'react-icons/ri';
import { IoSave } from 'react-icons/io5';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import {
  enableDropoffCodeEdit,
  enablePickupCodeEdit,
  getAdminCodes,
  setAdminDropoffCode,
  setAdminPickupCode,
  updateDropoffCode,
  updatePickupCode,
} from '../adminCodeSlice';

const AdminCode = () => {
  const dispatch = useDispatch();

  const adminCodeState = useSelector((state) => state.setting.adminCode);

  const {
    requestLoading,
    requestError,
    pickupCode,
    pickupCodeEdit,
    updatePickupCodeLoading,
    dropoffCode,
    dropoffCodeEdit,
    updateDropoffCodeLoading,
  } = adminCodeState;

  useEffect(() => {
    dispatch(getAdminCodes())
      .unwrap()
      .then(() => {
        return;
      })
      .catch(() => {
        return;
      });
  }, [dispatch]);

  const handleUpdatePickupCode = () => {
    dispatch(updatePickupCode())
      .unwrap()
      .then((msg) => {
        toast.success(msg);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleUpdateDropoffCode = () => {
    dispatch(updateDropoffCode())
      .unwrap()
      .then((msg) => {
        toast.success(msg);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  if (requestLoading) {
    return (
      <div className='mt-5'>
        <Alert className='mt-3' variant='info'>
          <h4>Admin Codes</h4>
        </Alert>

        <div>
          <p className='lead'>
            Admin Codes are utilized to update the drop-off/pickup status on the Public Angel Tree website, along with {' '}
            <span style={{ fontWeight: 'bold' }}>Confirmation ID</span>.
          </p>
          <p className='lead'>
            The drop-off/pick-up status will be updated internally in the database and promptly reflected on
            both the Public web interface and the Admin Panel.
          </p>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <Spinner animation='border' role='status' variant='success'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (requestError) {
    return (
      <div className='mt-5'>
        <Alert className='mt-3' variant='info'>
          <h4>Admin Codes</h4>
        </Alert>

        <div>
          <p className='lead'>
            Admin Codes are utilized to update the drop-off/pickup status on the Public Angel Tree website, along with {' '}
            <span style={{ fontWeight: 'bold' }}>Confirmation ID</span>.
          </p>
          <p className='lead'>
            The drop-off/pick-up status will be updated internally in the database and promptly reflected on
            both the Public web interface and the Admin Panel.
          </p>
        </div>
        <p className='text-danger'>{requestError}</p>
      </div>
    );
  }

  return (
    <div className='mt-5'>
      <Alert className='mt-3' variant='info'>
        <h4>Admin Codes</h4>
      </Alert>
      <div>
        <p className='lead'>
          Admin Codes are utilized to update the drop-off/pickup status on the Public Angel Tree website, along with {' '}
          <span style={{ fontWeight: 'bold' }}>Confirmation ID</span>.
        </p>
        <p className='lead'>
          The drop-off/pick-up status will be updated internally in the database and promptly reflected on
          both the Public web interface and the Admin Panel.
        </p>
      </div>
      <Row>
        <Col xs='auto' md='6'>
          <>
            <Row className='align-items-center'>
              <Col className='my-1'>
                <Form.Label
                  htmlFor='inlineFormInputGroupPickupCode'
                  visuallyHidden
                >
                  Pickup Code
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>Pick-up Code</InputGroup.Text>
                  <FormControl
                    id='inlineFormInputGroupPickupCode'
                    placeholder='Enter Pick-up Code'
                    disabled={!pickupCodeEdit}
                    value={pickupCode}
                    onChange={(e) =>
                      dispatch(setAdminPickupCode(e.target.value))
                    }
                  />
                </InputGroup>
              </Col>

              <Col xs='auto' className='my-1'>
                {pickupCodeEdit ? (
                  <Button
                    variant='success'
                    onClick={handleUpdatePickupCode}
                    disabled={updatePickupCodeLoading}
                  >
                    {updatePickupCodeLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <>
                        {' '}
                        <IoSave size={20} /> Save
                      </>
                    )}
                  </Button>
                ) : (
                  <Button
                    variant='secondary'
                    onClick={() => dispatch(enablePickupCodeEdit())}
                  >
                    <RiEdit2Fill size={20} /> Edit
                  </Button>
                )}
              </Col>
            </Row>
          </>
        </Col>

        <Col xs='auto' md='6'>
          <>
            <Row className='align-items-center'>
              <Col className='my-1'>
                <Form.Label
                  htmlFor='inlineFormInputGroupDropoffCode'
                  visuallyHidden
                >
                  Dropoff Code
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>Drop-off Code</InputGroup.Text>
                  <FormControl
                    id='inlineFormInputGroupDropoffCode'
                    placeholder='Enter Drop-off Code'
                    disabled={!dropoffCodeEdit}
                    value={dropoffCode}
                    onChange={(e) =>
                      dispatch(setAdminDropoffCode(e.target.value))
                    }
                  />
                </InputGroup>
              </Col>

              <Col xs='auto' className='my-1'>
                {dropoffCodeEdit ? (
                  <Button
                    onClick={handleUpdateDropoffCode}
                    variant='success'
                    disabled={updateDropoffCodeLoading}
                  >
                    {updateDropoffCodeLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <>
                        {' '}
                        <IoSave size={20} /> Save
                      </>
                    )}
                  </Button>
                ) : (
                  <Button
                    variant='secondary'
                    onClick={() => dispatch(enableDropoffCodeEdit())}
                  >
                    <RiEdit2Fill size={20} /> Edit
                  </Button>
                )}
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default AdminCode;
