import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  DropdownButton,
  Dropdown,
  Container,
  Row,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteManySponsors,
  deleteSingleSponsor,
  selectManySponsors,
} from './sponsorFormSlice';
import { fetchSponsorInfo } from './sponsorInfoSlice';
import CustomTable from '../../customs/CustomTable';
import { COLUMNS } from './sponsorColumns';
import { IoAdd, IoTrash } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

const SponsorInfo = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const [setTotalSponsors] = useOutletContext();

  const dispatch = useDispatch();
  const sponsorInfo = useSelector((state) => state.sponsor.sponsorInfo);
  const sponsorForm = useSelector((state) => state.sponsor.sponsorForm);

  const { loading, error, data } = sponsorInfo;
  const { deleteLoading } = sponsorForm;

  useEffect(() => {
    dispatch(fetchSponsorInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) setTotalSponsors(data.length);
    return () => {
      setTotalSponsors(0);
    };
  }, [data.length, setTotalSponsors]);

  const handleDeleteSingleSponsor = (userId) => {
    if (window.confirm('Do you really want to delete this Sponsor?')) {
      dispatch(deleteSingleSponsor(userId))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchSponsorInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleBulkUpdateDropoffStatus = (selectedSponsors) => {
    dispatch(selectManySponsors(selectedSponsors));
    navigate('edit/dropoff-status');
  };

  const handleDeleteManySponsors = (rows) => {
    if (window.confirm('Do you really want to delete those Sponsors?')) {
      const userIds = rows.map((row) => row.userId);
      dispatch(deleteManySponsors(userIds))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchSponsorInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (error)
    return (
      <Container className='mt-5'>
        <Alert className='text-center' variant='danger'>
          {error}
        </Alert>
      </Container>
    );

  if (loading) {
    return (
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '50vh' }}
      >
        <Spinner animation='border' role='status' variant='success'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='m-3 m-sm-3 m-md-3'>
      <Row className='flex-column align-items-center justify-content-center'>
        <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
          <Button as={Link} to={'new'} variant='primary'>
            <IoAdd size={25} /> New Sponsor
          </Button>
        </Col>

        {selectedRows.length === 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                as={Link}
                to={`${selectedRows[0].userId}/edit`}
                variant='outline-secondary'
              >
                <RiEdit2Fill size={25} /> Edit Sponsor
              </Button>
            </Col>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                variant='danger'
                disabled={deleteLoading}
                onClick={() =>
                  handleDeleteSingleSponsor(selectedRows[0].userId)
                }
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete Sponsor
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}

        {selectedRows.length > 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <DropdownButton
                id='dropdown-basic-button'
                title={`Update ${selectedRows.length} Sponsors`}
                align={{ lg: 'end' }}
                as={ButtonGroup}
                variant='outline-secondary'
              >
                <Dropdown.Item
                  onClick={() => handleBulkUpdateDropoffStatus(selectedRows)}
                >
                  Dropoff Status
                </Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                variant='outline-danger'
                disabled={deleteLoading}
                onClick={() => handleDeleteManySponsors(selectedRows)}
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete {selectedRows.length} Sponsors
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}
      </Row>
      <div>
        {data.length > 0 ? (
          <CustomTable
            data={data}
            columns={COLUMNS}
            fileName='sponsor-information'
            headerStyles={{ backgroundColor: '#FA8115', color: '#ffffff' }}
            setSelectedRows={setSelectedRows}
          />
        ) : (
          <div className='d-flex flex-row justify-content-center mt-5'>
          <Alert className='w-75 text-center' variant='warning'>
            No sponsors. Sponsor can be added from the Admin panel or from
            Public Angel Tree website when they select Angel(s).
          </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsorInfo;
