import React from 'react';
import { Alert } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const CustomBarChart = ({ data, title, COLORS, dataKeyX, dataKeyY = 'number' }) => {
  return (
    <div>
      <p className='text-center'>{title}</p>
      {data.length === 0 ? (
        <Alert className='text-center' variant='warning'>
          No available data
        </Alert>
      ) : (
        <BarChart width={400} height={400} data={data}>
          <XAxis dataKey={dataKeyX} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={dataKeyY} fill={COLORS[0]} />
        </BarChart>
      )}
    </div>
  );
};

export default CustomBarChart;
