import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const TableGlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce(
    (value) => setFilter(value || undefined),
    750
  );

  return (
    <div className='w-50'>
      <Form.Control
        type='text'
        aria-describedby='searching'
        placeholder='Search anything...'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default TableGlobalFilter;
