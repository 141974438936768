import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteManyChildren,
  deleteSingleChild,
} from './childFormSlice';
import { fetchChildInfo } from './childInfoSlice';
import CustomTable from '../../customs/CustomTable';
import { COLUMNS } from './childColumns';
import { IoAdd, IoTrash } from 'react-icons/io5';
import { RiEdit2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

const ChildInfo = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const [setTotalChildren] = useOutletContext();

  const dispatch = useDispatch();
  const childInfo = useSelector((state) => state.child.childInfo);
  const childForm = useSelector((state) => state.child.childForm);

  const { loading, error, data } = childInfo;
  const { deleteLoading } = childForm;

  useEffect(() => {
    dispatch(fetchChildInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) setTotalChildren(data.length);
    return () => {
      setTotalChildren(0);
    };
  }, [data.length, setTotalChildren]);

  const handleDeleteSingleChild = (childId) => {
    if (window.confirm('Do you really want to delete this Angel?')) {
      dispatch(deleteSingleChild(childId))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchChildInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleDeleteManyChildren = (rows) => {
    if (window.confirm('Do you really want to delete those Angels?')) {
      const childIds = rows.map((row) => row.childId);
      dispatch(deleteManyChildren(childIds))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          dispatch(fetchChildInfo())
            .unwrap()
            .then(() => {
              return;
            })
            .catch((err) => {
              return;
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (error)
    return (
      <Container className='mt-5'>
        <Alert className='text-center' variant='danger'>
          {error}
        </Alert>
      </Container>
    );

  if (loading) {
    return (
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '50vh' }}
      >
        <Spinner animation='border' role='status' variant='success'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='m-3 m-sm-3 m-md-3'>
      <Row className='flex-column align-items-center justify-content-center'>
        <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
          <Button as={Link} to={'new'} variant='primary'>
            <IoAdd size={25} /> New Angel
          </Button>
        </Col>

        {selectedRows.length === 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                as={Link}
                to={`${selectedRows[0].childId}/edit`}
                variant='outline-secondary'
              >
                <RiEdit2Fill size={25} /> Edit Angel
              </Button>
            </Col>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                as={Link}
                to={'#'}
                variant='outline-danger'
                disabled={deleteLoading}
                onClick={() => handleDeleteSingleChild(selectedRows[0].childId)}
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete Angel
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}

        {selectedRows.length > 1 && (
          <>
            <Col className='d-grid gap-2 mt-2' sm={12} md={12} lg={{ span: 2 }}>
              <Button
                as={Link}
                to={'#'}
                variant='outline-danger'
                disabled={deleteLoading}
                onClick={() => handleDeleteManyChildren(selectedRows)}
              >
                {deleteLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <span>
                    <IoTrash size={25} /> Delete {selectedRows.length} Angels
                  </span>
                )}
              </Button>
            </Col>
          </>
        )}
      </Row>
      <div>
        {data.length > 0 ? (
          <CustomTable
            data={data}
            columns={COLUMNS}
            fileName='Angel-information'
            headerStyles={{ backgroundColor: '#CE4D4D', color: '#ffffff' }}
            setSelectedRows={setSelectedRows}
          />
        ) : (
          <div className='d-flex flex-row justify-content-center mt-5'>
            <Alert className='w-75 text-center' variant='warning'>
              No Angels. Angel can be added from the Admin panel or from Public
              Angel Tree website a when a parent registers an application.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChildInfo;
