import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './components/layout/Layout';
// Auth
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import AuthLayout from './components/auth/AuthLayout';
import ForgetPassordPage from './components/auth/ForgetPassordPage';
import ResetPasswordPage from './components/auth/ResetPasswordPage';
import ActivateAccount from './components/auth/ActivateAccount';
import RequireAuth from './components/auth/RequireAuth';

// Dashboard and Settings
import Dashboard from './components/Home/Dashboard';
import SettingPage from './components/setting/SettingPage';

// Parent
import ParentLayout from './components/parent/layout/ParentLayout';
import ParentInfo from './components/parent/ParentInfo';
import NewParentForm from './components/parent/new/NewParentForm';
import EditParent from './components/parent/edit/EditParent';

// Child
import ChildLayout from './components/child/layout/ChildLayout';
import ChildInfo from './components/child/ChildInfo';
import NewChildForm from './components/child/new/NewChildForm';
import EditChild from './components/child/edit/EditChild';

// Sponsor
import SponsorLayout from './components/sponsor/layout/SponsorLayout';
import SponsorInfo from './components/sponsor/SponsorInfo';
import NewSponsorForm from './components/sponsor/new/NewSponsorForm';
import EditSponsor from './components/sponsor/edit/EditSponsor';
import ApprovedStatus from './components/parent/edit/ApprovedStatus';
import PickupStatus from './components/parent/edit/PickupStatus';
import DropoffStatus from './components/sponsor/edit/DropoffStatus';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='auth' element={<AuthLayout />}>
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route path='forgotpassword' element={<ForgetPassordPage />} />
            <Route path='resetpassword' element={<ResetPasswordPage />} />
            <Route path='activate' element={<ActivateAccount />} />
          </Route>

          <Route
            index
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />

          <Route
            path='parent'
            element={
              <RequireAuth>
                <ParentLayout />
              </RequireAuth>
            }
          >
            <Route index element={<ParentInfo />} />
            <Route path='new' element={<NewParentForm />} />
            <Route path='edit/:userId' element={<EditParent />} />
            <Route path='edit/approved-status' element={<ApprovedStatus />} />
            <Route path='edit/pickup-status' element={<PickupStatus />} />
          </Route>
          <Route
            path='child'
            element={
              <RequireAuth>
                <ChildLayout />
              </RequireAuth>
            }
          >
            <Route index element={<ChildInfo />} />
            <Route path='new' element={<NewChildForm />} />
            <Route path=':childId/edit' element={<EditChild />} />
          </Route>
          <Route
            path='sponsor'
            element={
              <RequireAuth>
                <SponsorLayout />
              </RequireAuth>
            }
          >
            <Route index element={<SponsorInfo />} />
            <Route path='new' element={<NewSponsorForm />} />
            <Route path=':userId/edit' element={<EditSponsor />} />
            <Route path='edit/dropoff-status' element={<DropoffStatus />} />
          </Route>
          <Route
            path='setting'
            element={
              <RequireAuth>
                <SettingPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>

      <ToastContainer position='top-center'
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true} 
        theme='colored'/>
    </>
  );
}

export default App;
