import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialChildFormState = {
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    success: false,
    error: null,
    updateError: null,
    deleteError: null,
    data: null,
    selectedParents: [],
};

export const submitChildFormToServer = createAsyncThunk('childForm/submitChildFormToServer', async (data, { rejectWithValue }) => {
    data.parentId = Number(data.parentId);

    try {
        const res = await api.post('/child/admin-child', data);

        return res.data?.message;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Angel form could not be submitted');
    }
});

// Get single child info
export const getSingleChildInfo = createAsyncThunk('childForm/getSingleChildInfo', async (childId, { rejectWithValue }) => {
    try {
        const [res1, res2] = await Promise.all(
            [
                api.get(`/child?childId=${childId}`),
                api.get('/sponsor')
            ]
        );

        const data = {
            childInfo: res1?.data?.data[0] || null,
            sponsors: res2?.data.data || [],
        };

        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not fetch this Angel');
    }
});

// Update a single child
export const updateSingleChildInfo = createAsyncThunk('childForm/updateSingleChildInfo', async ({ data, childId }, { rejectWithValue }) => {
    if (data.sponsorId === '') data.sponsorId = null;

    try {
        const res = await api.put(`/child/${childId}`, data);

        return res.data?.message || `Child ID ${childId} successfully updated`;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update this Angel information');
    }
});

// Delete a single child
export const deleteSingleChild = createAsyncThunk('childForm/deleteSingleChild', async (childId, { rejectWithValue }) => {
    try {
        const res = await api.delete(`/child/${childId}`);

        return res.data.message || 'Angel successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'This Angel could not be deleted');
    }
});


// Delete many children
export const deleteManyChildren = createAsyncThunk('childForm/deleteManyChildren', async (childIds, { rejectWithValue }) => {
    try {
        const res = await api.patch('/child', { childIds });

        return res.data.message || 'Angels successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'Angels could not be deleted');
    }
});

const childFormSlice = createSlice({
    name: 'childForm',
    initialState: initialChildFormState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitChildFormToServer.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitChildFormToServer.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(submitChildFormToServer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getSingleChildInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleChildInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getSingleChildInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSingleChildInfo.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateSingleChildInfo.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateSingleChildInfo.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(deleteSingleChild.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteSingleChild.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteSingleChild.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
            .addCase(deleteManyChildren.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteManyChildren.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteManyChildren.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
    }
});

export default childFormSlice.reducer;