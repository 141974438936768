import React, { useState } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';

import { IoPersonAdd } from 'react-icons/io5';
import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
import { registerAccount } from './authSlice';

const Register = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const [userRegister, setUserRegister] = useState({
    username: '',
    email: '',
    passwd: '',
    passwd2: '',
  });

  const { username, email, passwd, passwd2 } = userRegister;

  const { loading, isAuthenticated } = authState;

  const onChange = (e) => {
    setUserRegister({ ...userRegister, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Only @ufvus.ca domain can register
    const emailPattern = /^[\w-.]+@(ufvsus)\.ca$/g;

    if (!emailPattern.test(email))
      toast.error('Please use UFVSUS email to register');
    else if (passwd !== passwd2) toast.error('Passwords do not match');
    else {
      const formData = {
        ...userRegister,
        role: 'admin',
      };
      delete formData.passwd2;
      dispatch(registerAccount(formData))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          setUserRegister({
            username: '',
            email: '',
            passwd: '',
            passwd2: '',
          });
          navigate('/auth/login');
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (isAuthenticated)
    return <Navigate to='/dashboard' state={{ from: location }} replace />;

  return (
    <Container className='mt-5 p-5' fluid='md'>
      <Card>
        <Card.Body>
          <Card.Title className='text-center'>
            <IoPersonAdd size={100} />
            <h2 className='mt-3'>Register An Account</h2>
          </Card.Title>
          <Form onSubmit={handleSubmit} className='w-100 mt-3'>
            <Form.Group className='mb-3' controlId='formBasicUsername'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Username</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='text'
                  name='username'
                  required
                  max={90}
                  placeholder='username without space'
                  value={username}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Email address</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='email'
                  name='email'
                  required
                  max={90}
                  placeholder='UFV SUS email'
                  value={email}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Password</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='password'
                  name='passwd'
                  required
                  max={50}
                  placeholder='Password'
                  value={passwd}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword2'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Confirm Password</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='password'
                  name='passwd2'
                  required
                  max={50}
                  placeholder='Confirm Password'
                  value={passwd2}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>

            <div className='text-center p-3'>
              <Button
                variant='primary'
                type='submit'
                size='lg'
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </div>

            <div className='text-center p-3'>
              Have an account? <a href='/auth/login'>Login now</a>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Register;
