class TokenService {
  getLocalAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  removeToken() {
    localStorage.removeItem('accessToken');
  }
}

export default new TokenService();
