import React from 'react';

const Footer = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        marginTop: '4rem',
        height: '2rem',
      }}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: `Copyright &copy ${new Date().getFullYear()} UFV Student Union Society, All rights reserved.`,
        }}
      ></p>
    </div>
  );
};

export default Footer;
