import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ImGift } from 'react-icons/im';
import { Badge } from 'react-bootstrap';

const SponsorLayout = () => {
  const [totalSponsors, setTotalSponsors] = useState(0);

  return (
    <>
      <div className='m-3 m-sm-3 m-md-3 mb-3'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <span>
            {totalSponsors > 0 && (
              <Badge style={{ fontSize: '25px' }} pill bg='secondary'>
                {totalSponsors}
              </Badge>
            )}
          </span>
          <h1 style={{ fontSize: '60px', letterSpacing: '4px', textAlign: 'center' }}>
            <span>
              <ImGift size={60} /> Sponsors
            </span>
          </h1>

        </div>
      </div>
      <Outlet context={[setTotalSponsors]} />
    </>
  );
};

export default SponsorLayout;
