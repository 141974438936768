import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RiParentFill } from 'react-icons/ri';
import { Badge } from 'react-bootstrap';

const ParentLayout = () => {
  const [totalParents, setTotalParents] = useState(0);
  return (
    <>
      <div className='m-3 m-sm-3 m-md-3 mb-3'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <span>
            {totalParents > 0 && (
              <Badge style={{ fontSize: '25px' }} pill bg='secondary'>
                {totalParents}
              </Badge>
            )}
          </span>
          <h1 style={{ fontSize: '60px', letterSpacing: '4px', textAlign: 'center' }}>
            <span>
              <RiParentFill size={60} /> Parents
            </span>
          </h1>
        </div>
      </div>
      <Outlet context={[setTotalParents]} />
    </>
  );
};

export default ParentLayout;
