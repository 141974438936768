import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialState = {
    requestLoading: false,
    requestSuccess: false,
    requestError: null,
    pickupCode: '',
    pickupCodeEdit: false,
    updatePickupCodeLoading: false,
    updatePickupCodeError: null,
    dropoffCode: '',
    dropoffCodeEdit: false,
    updateDropoffCodeLoading: false,
    updateDropoffCodeError: null,
};

export const getAdminCodes = createAsyncThunk('adminCode/getAdminCodes', async (_, { rejectWithValue }) => {
    try {
        const res = await api.get('/admin-code');
        const pickupCodeFromRes = res.data?.data.find((code) => code.codeName === 'pickup');
        const dropoffCodeFromRes = res.data?.data.find((code) => code.codeName === 'dropoff');

        return {
            pickupCode: pickupCodeFromRes?.confirmCode || '',
            dropoffCode: dropoffCodeFromRes?.confirmCode || '',
        };

    } catch (error) {
        return rejectWithValue(
            error?.response?.data?.message || 'Admin Codes could not be fetched.'
        );
    }
});

export const updatePickupCode = createAsyncThunk('adminCode/updatePickupCode', async (_, { getState, rejectWithValue }) => {
    const code = getState().setting.adminCode.pickupCode;

    try {
        const res = await api.put('/admin-code/pickup', { confirmCode: code });
        return res.data?.message || 'Pickup code successfully updated';
    } catch (error) {
        return rejectWithValue('Pickup code could not be updated.');
    }
});

export const updateDropoffCode = createAsyncThunk('adminCode/updateDropoffCode', async (_, { getState, rejectWithValue }) => {
    const code = getState().setting.adminCode.dropoffCode;

    try {
        const res = await api.put('/admin-code/dropoff', { confirmCode: code });
        return res.data?.message || 'Dropoff code successfully updated';
    } catch (error) {
        return rejectWithValue('Dropoff code could not be updated.');
    }
});

const adminCodeSlice = createSlice({
    name: 'adminCode',
    initialState,
    reducers: {
        enablePickupCodeEdit: (state) => {
            state.pickupCodeEdit = true;
        },
        disablePickupCodeEdit: (state) => {
            state.pickupCodeEdit = false;
        },
        setAdminPickupCode: (state, action) => {
            state.pickupCode = action.payload;
        },
        enableDropoffCodeEdit: (state) => {
            state.dropoffCodeEdit = true;
        },
        disableDropoffCodeEdit: (state) => {
            state.dropoffCodeEdit = false;
        },
        setAdminDropoffCode: (state, action) => {
            state.dropoffCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminCodes.pending, (state) => {
                state.requestLoading = true;
                state.requestSuccess = false;
                state.requestError = null;
            })
            .addCase(getAdminCodes.fulfilled, (state, action) => {
                state.requestLoading = false;
                state.requestSuccess = true;
                state.pickupCode = action.payload.pickupCode;
                state.dropoffCode = action.payload.dropoffCode;
            })
            .addCase(getAdminCodes.rejected, (state, action) => {
                state.requestLoading = false;
                state.requestSuccess = false;
                state.requestError = action.error.message;
            })
            .addCase(updatePickupCode.pending, (state, action) => {
                state.updatePickupCodeLoading = true;
                state.pickupCodeEdit = false;
            })
            .addCase(updatePickupCode.fulfilled, (state, action) => {
                state.updatePickupCodeLoading = false;
            })
            .addCase(updatePickupCode.rejected, (state, action) => {
                state.updatePickupCodeLoading = false;
                state.updatePickupCodeError = action.error.message;
            })
            .addCase(updateDropoffCode.pending, (state, action) => {
                state.updateDropoffCodeLoading = true;
                state.dropoffCodeEdit = false;
            })
            .addCase(updateDropoffCode.fulfilled, (state, action) => {
                state.updateDropoffCodeLoading = false;
            })
            .addCase(updateDropoffCode.rejected, (state, action) => {
                state.updateDropoffCodeLoading = false;
                state.updateDropoffCodeError = action.error.message;
            });
    },
});

export const {
    enablePickupCodeEdit,
    disablePickupCodeEdit,
    setAdminPickupCode,
    enableDropoffCodeEdit,
    disableDropoffCodeEdit,
    setAdminDropoffCode,
} = adminCodeSlice.actions;

export default adminCodeSlice.reducer;
