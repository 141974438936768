import React from 'react';
import { Container } from 'react-bootstrap';
import { IoSettings } from 'react-icons/io5';
import AdminCode from './AdminCode/AdminCode';
import DatabaseRemove from './database/DatabaseRemove';
import PasswordChange from './passswordSection/PasswordChange';
import UserChange from './userSection/UserChange';

const SettingPage = () => {
  return (
    <Container>
      <h1 style={{ fontSize: '50px', letterSpacing: '2px' }}>
        <span>
          <IoSettings size={60} /> Settings
        </span>
      </h1>
      <AdminCode />
      <PasswordChange />
      <UserChange />
      <DatabaseRemove />
    </Container>
  );
};

export default SettingPage;
