import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MdFace } from 'react-icons/md';
import { Badge } from 'react-bootstrap';

const ChildLayout = () => {
  const [totalChildren, setTotalChildren] = useState(0);

  return (
    <>
      <div className='m-3 m-sm-3 m-md-3 mb-3'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <span>
            {totalChildren > 0 && (
              <Badge style={{ fontSize: '25px' }} pill bg='secondary'>
                {totalChildren}
              </Badge>
            )}
          </span>

          <h1 style={{ fontSize: '60px', letterSpacing: '4px' }}>
            <span>
              <MdFace size={60} /> Angels
            </span>

          </h1>
        </div>
      </div>
      <Outlet context={[setTotalChildren]} />
    </>
  );
};

export default ChildLayout;
