import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

import { VscDebugRestart } from 'react-icons/vsc';

import { useParams, useNavigate } from 'react-router-dom';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import parsePhoneNumber from 'libphonenumber-js';
import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
// Import sponsor actions
import {
  getSingleSponsorInfo,
  updateSingleSponsorInfo,
} from '../sponsorFormSlice';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(\D*)$/, 'Name should not container number')
    .required('Full Name Required'),
  email: yup
    .string()
    .email('Incorrect Email Format')
    .required('Email Required'),
  title: yup.string().required('Title required'),
  dropoffLocation: yup.string().required('Pick up location required'),
  phone: yup
    .string()
    .required('Phone number required')
    .min(14, 'Phone number must have 10 digits')
    .max(14, 'Phone number must have 10 digits'),
  selectedChildren: yup.array().of(
    yup
      .object()
      .shape({
        label: yup.string().nullable(true),
        value: yup.string().nullable(true),
      })
      .nullable(true)
  ),
});

const EditSponsor = () => {
  let params = useParams();
  const navigate = useNavigate();

  const sponsorForm = useSelector((state) => state.sponsor.sponsorForm);
  const dispatch = useDispatch();

  const { loading, updateLoading, error, data } = sponsorForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      name: data?.sponsorInfo.sponsorName || '',
      email: data?.sponsorInfo.email || '',
      phone: data?.sponsorInfo.phone || '',
      title: data?.sponsorInfo.title || '',
      dropoffLocation: data?.sponsorInfo.dropoffLocation || '',
      dropoff: data?.sponsorInfo.dropoff === 'Yes' ? '1' : '0',
      sendEmail: 0,
      selectedChildren: [],
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(getSingleSponsorInfo(params.userId))
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  }, [dispatch, params.userId]);

  useEffect(() => {
    let filteredChildren = [];
    if (data) {
      if (
        data?.children.length > 0 &&
        data?.sponsorInfo.selectedChildIds.length > 0
      ) {
        // Filter array of objects based on another array
        filteredChildren = data.children.filter((child) => {
          return (
            [...data?.sponsorInfo?.selectedChildIds].indexOf(child.childId) !==
            -1
          );
        });
      }

      // Convert filtered array into React-Select [{values, label}]
      const matchedChildren = filteredChildren.map((child) => {
        return {
          value: child.childId,
          label: `${child.childName}, Angel ID: ${child.childId}`,
        };
      });

      reset({
        name: data?.sponsorInfo.sponsorName,
        email: data?.sponsorInfo.email,
        phone: data?.sponsorInfo.phone,
        title: data?.sponsorInfo.title,
        dropoffLocation: data?.sponsorInfo.dropoffLocation,
        dropoff: data?.sponsorInfo.dropoff === 'Yes' ? '1' : '0',
        selectedChildren: matchedChildren,
      });
    }
  }, [data, reset]);

  const dropoff = watch('dropoff');

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');
    if (!phoneNumber) return value;
    return phoneNumber.formatNational();
  };

  const childrenOptions = useMemo(() => {
    if (data?.children.length === 0 || data?.children === undefined) return [];

    const childrenArray = data?.children.map((child) => {
      return {
        value: child.childId,
        label: `${child.childName}, Angel ID: ${child.childId}`,
      };
    });
    childrenArray.sort((a, b) => a.label.localeCompare(b.label)); // sort by childName

    return childrenArray;
  }, [data?.children]);

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to update this Sponsor?')) {
      const formData = {
        ...values,
        sponsorId: data?.sponsorInfo.sponsorId,
        dropoffId: data?.sponsorInfo.dropoffId,
      };

      // delete selectedChildren in formData to get childIds only
      delete formData.selectedChildren;

      const selectedChildIds = values.selectedChildren.map(
        (selectedChild) => selectedChild.value
      );

      formData.selectedChildIds = selectedChildIds;

      dispatch(updateSingleSponsorInfo(formData))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          navigate('/sponsor', { replace: true });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (loading)
    return (
      <Container className='mt-5'>
        <div>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Button
            variant='outline-secondary'
            className='mb-3'
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoArrowBack /> Go Back
          </Button>
          <h1>
            <span>
              <FiEdit size={50} />
            </span>{' '}
            Edit Sponsor
          </h1>
        </div>

        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{ height: '50vh' }}
        >
          <Spinner animation='border' role='status' variant='success'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </Container>
    );

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <FiEdit size={50} />
          </span>{' '}
          Edit Sponsor{' '}
          {data && (
            <span className='text-danger'>
              ID {data?.sponsorInfo?.sponsorId}
            </span>
          )}
        </h1>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Sponsor Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Sponsor's Information</h4>
        </Alert>

        {/* Full Name */}
        <Form.Group className='mb-3' controlId='formBasicName'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type='text'
            {...register('name')}
            placeholder='Enter Full Name'
            isValid={dirtyFields?.name && !errors?.name}
            isInvalid={errors?.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email Address */}
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            {...register('email')}
            isValid={dirtyFields?.email && !errors?.email}
            placeholder='Enter Email'
            isInvalid={errors?.email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Phone Number (using Controller for formatting the phone number) */}
        <Form.Group className='mb-3' controlId='formBasicPhone'>
          <Form.Label>Phone Number</Form.Label>
          <Controller
            name='phone'
            control={control}
            render={({ field }) => (
              <Form.Control
                {...field}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(event) =>
                  field.onChange(normalizePhoneNumber(event.target.value))
                }
                isValid={dirtyFields?.phone && !errors?.phone}
                isInvalid={errors?.phone}
              />
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* What describes the sponsor the best */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>What describes the sponsor the best?</Form.Label>
          <Controller
            name='title'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={dirtyFields?.title && !errors.title}
                isInvalid={errors?.title}
              >
                <option value=''>Please select a title</option>
                <option value='Student'>Student</option>
                <option value='Administration/Staff'>
                  Administration/Staff
                </option>
                <option value='Faculty'>Faculty</option>
                <option value='Alumni'>Alumni</option>
                <option value='External'>External</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.title?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Dropoff Location */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>Dropoff Location</Form.Label>
          <Controller
            name='dropoffLocation'
            control={control}
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={
                  dirtyFields?.dropoffLocation && !errors?.dropoffLocation
                }
                isInvalid={errors?.dropoffLocation}
              >
                <option value=''>Please select a dropoff location</option>
                <option value='Abbotsford'>
                  Abbotsford: Abby Campus
                </option>
                <option value='Chilliwack'>
                  Chilliwack: CEP Campus
                </option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.dropoffLocation?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Dropoff Status */}

        <Form.Group className='mb-3' controlId='formApproved'>
          <Form.Label className='me-3'>Dropped Off Angel Wish?</Form.Label>
          <Form.Check
            inline
            type='radio'
            name='dropoffGroup'
            id='inline-radio-dropoff'
            value={1}
            label='Yes'
            defaultChecked={data && data?.sponsorInfo.dropoff === 'Yes'}
            {...register('dropoff')}
          />
          <Form.Check
            inline
            type='radio'
            name='dropoffGroup'
            id='inline-radio-dropoff'
            value={0}
            label='No'
            defaultChecked={data && data?.sponsorInfo.dropoff === 'No'}
            {...register('dropoff')}
          />
        </Form.Group>

        {/* Send Confirmation email? */}
        {dropoff === '1' ? (
          <Form.Group className='mb-3' controlId='formApproved'>
            <Form.Label className='me-3'>
              Send Dropoff Confirmation Email?
            </Form.Label>
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-1'
              value={1}
              label='Yes'
              {...register('sendEmail')}
            />
            <Form.Check
              inline
              type='radio'
              name='sendEmailGroup'
              id='inline-radio-email-2'
              value={0}
              label='No'
              {...register('sendEmail')}
              defaultChecked
            />
          </Form.Group>
        ) : (
          <></>
        )}

        {/* Children Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Angel Sponsorship</h4>
        </Alert>

        <div className='mb-3'>
          <p className='lead'>
            This sponsor is sponsoring {''}
            <span className='text-danger'>
              {data?.sponsorInfo.numberOfAngels}
            </span>{' '}
            Angel(s).{' '}
            {data?.sponsorInfo.numberOfAngels > 0 && (
              <>
                Angel IDs:{' '}
                <span className='text-danger'>
                  {data?.sponsorInfo.selectedChildIds.join(', ')}
                </span>
                .
              </>
            )}
          </p>

          {/* Select children by childId */}
          {data?.children.length > 0 ? (
            <>
              <Form.Group className='mb-3' controlId='formBasicName'>
                <Form.Label>Angel(s) (optional)</Form.Label>

                <Controller
                  name='selectedChildren'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isSearchable={true}
                      isClearable={true}
                      options={childrenOptions}
                      isMulti
                      placeholder='Select an Angel'
                    />
                  )}
                />
              </Form.Group>
              <p className='text-danger'>
                Warning: By adding new Angel(s) to this Sponsor, these new
                Angel(s) will be withdrawn from other Sponsors if these Angels
                were already picked by other Sponsors.
              </p>
            </>
          ) : (
            <>
              <Alert variant='warning'>
                There are no Angels available. Please add a new Angel before
                adding Angel to this Sponsor
              </Alert>
            </>
          )}
        </div>

        <div className='d-grid gap-2'>
          <Button variant='outline-secondary' type='reset' size='lg'>
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='success' type='submit' size='lg' disabled={loading}>
            {updateLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Save Changes <IoSave />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditSponsor;
