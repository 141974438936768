import React, { useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/api';

const DatabaseRemove = () => {
  const [loading, setLoading] = useState(false);

  const handleFlushingDatabase = async () => {
    if (window.confirm('Do you really want to flush the database?')) {
      setLoading(true);
      setTimeout(async () => {
        try {
          let res = await api.delete('/data');
          toast.success(res.data?.message || 'Database successfully flushed');
        } catch (err) {
          toast.error(err.response.data.message || 'Something went wrong');
        } finally {
          setLoading(false);
        }
      }, 2000);
    }
  };

  return (
    <div className='mt-5'>
      <Alert variant='warning'>
        <h4>Flush Database (Warning)</h4>
      </Alert>
      <p className='lead'>
        To initiate the new Angel Tree Program, we recommend clearing the database to remove data from the previous year.
        To do so, please click{' '}
        <Button
          variant='danger'
          size='sm'
          onClick={() => handleFlushingDatabase()}
          disabled={loading}
        >
          {loading ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          ) : (
            <span>here</span>
          )}
        </Button>
        . Prior to flushing the database, ensure you export data from the{' '}
        <span style={{ fontWeight: 'bold' }}>Parent, Angel, Sponsor</span>{' '}
        tables as CSV files files for safekeeping.
      </p>
    </div>
  );
};

export default DatabaseRemove;
