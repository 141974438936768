import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutAccount } from '../../auth/authSlice';
import api from '../../../services/api';

const UserChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const handleLogout = async () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const msg = await api.delete(`/auth/${user.userId}`);
        dispatch(logoutAccount())
          .unwrap()
          .then(() => {
            navigate('/auth/register');
            toast.success(msg);
          })
          .catch((err) => toast.error(err));
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div className='mt-5'>
      <Alert variant='info'>
        <h4>Change username or Email</h4>
      </Alert>
      <p className='lead'>
        For the security reasons, this user will be deleted, and you will need
        to register a new account with your new username/email to use this Admin
        Panel. Click{' '}
        <Button variant='danger' size='sm' onClick={() => handleLogout()}>
          here
        </Button>{' '}
        to delete this user. Please be aware that you will be logged out.
      </p>
    </div>
  );
};

export default UserChange;
