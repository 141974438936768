import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const initialSponsorFormState = {
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    success: false,
    error: null,
    updateError: null,
    deleteError: null,
    data: null,
    selectedSponsors: [],
};

export const submitSponsorFormToServer = createAsyncThunk('sponsorForm/submitSponsorFormToServer', async (data, { rejectWithValue }) => {
    try {
        const res = await api.post(`/sponsor/admin-sponsor`, data);

        return res.data?.message || 'Sponsor successfully added!';
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Sponsor form could not be submitted');
    }
});

// Get single sponsor info
export const getSingleSponsorInfo = createAsyncThunk('sponsorForm/getSingleSponsorInfo', async (userId, { rejectWithValue }) => {
    try {
        const [res1, res2] = await Promise.all(
            [
                api.get(`/sponsor?userId=${userId}`),
                api.get('/child')
            ]
        );

        const data = {
            sponsorInfo: res1?.data?.data[0],
            children: res2?.data?.data,
        };

        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not fetch this sponsor');
    }
});

// Update a single sponsor
export const updateSingleSponsorInfo = createAsyncThunk('sponsorForm/updateSingleSponsorInfo', async (data, { rejectWithValue }) => {
    const sponsorInfo = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        title: data.title,
        selectedChildIds: data.selectedChildIds,
    };

    const dropoffInfo = {
        dropoffLocation: data.dropoffLocation,
        dropoff: data.dropoff,
        sendEmail: data.sendEmail,
    };

    try {
        await Promise.all(
            [
                api.put(`/sponsor/${data.sponsorId}`, sponsorInfo),
                api.put(`/sponsor/dropoff/${data.dropoffId}`, dropoffInfo)
            ]
        );

        return `Sponsor ID ${data.sponsorId} successfully updated`;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update sponsor information');
    }
});

// Update the pickup status of many parents
export const updateSponsorsDropoffStatus = createAsyncThunk('sponsorForm/updateSponsorsDropoffStatus', async (newUpdatedSponsors, { rejectWithValue }) => {
    try {
        const res = await api.patch('/sponsor/dropoff/dropoff-status', { sponsors: newUpdatedSponsors });

        return res.data.message || `Pickup statuses of ${newUpdatedSponsors.length} sponsors successfully updated.`
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Could not update dropoff status of these sponsors');
    }
});

// Delete a single sponsor
export const deleteSingleSponsor = createAsyncThunk('sponsorForm/deleteSingleSponsor', async (userId, { rejectWithValue }) => {
    try {
        const res = await api.delete(`/sponsor/${userId}`);

        return res.data.message || 'Sponsor successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'This Sponsor could not be deleted');
    }
});

// Delete many sponsors
export const deleteManySponsors = createAsyncThunk('sponsorForm/deleteManySponsors', async (userIds, { rejectWithValue }) => {
    try {
        const res = await api.patch('/sponsor', { userIds });

        return res.data.message || 'Sponsors successfully deleted!';
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'Sponsors could not be deleted');
    }
});

const sponsorFormSlice = createSlice({
    name: 'sponsorForm',
    initialState: initialSponsorFormState,
    reducers: {
        // Select many sponsors for bulk update
        selectManySponsors: (state, action) => {
            state.selectedSponsors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitSponsorFormToServer.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitSponsorFormToServer.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(submitSponsorFormToServer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getSingleSponsorInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleSponsorInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getSingleSponsorInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSingleSponsorInfo.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateSingleSponsorInfo.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateSingleSponsorInfo.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(updateSponsorsDropoffStatus.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(updateSponsorsDropoffStatus.fulfilled, (state) => {
                state.updateLoading = false;
            })
            .addCase(updateSponsorsDropoffStatus.rejected, (state, action) => {
                state.updateLoading = false;
                state.updateError = action.payload;
            })
            .addCase(deleteSingleSponsor.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteSingleSponsor.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteSingleSponsor.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
            .addCase(deleteManySponsors.pending, (state) => {
                state.deleteLoading = true;
            })
            .addCase(deleteManySponsors.fulfilled, (state) => {
                state.deleteLoading = false;
            })
            .addCase(deleteManySponsors.rejected, (state, action) => {
                state.deleteLoading = false;
                state.deleteError = action.payload;
            })
    }
});

export const { selectManySponsors } = sponsorFormSlice.actions;
export default sponsorFormSlice.reducer;