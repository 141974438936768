import { format } from 'date-fns';

export const COLUMNS = [
  {
    Header: 'Sponsor Information',
    columns: [
      {
        Header: 'Sponsor ID',
        accessor: 'sponsorId',
      },
      {
        Header: 'Sponsor Name',
        accessor: 'sponsorName',
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 100,
      },
      {
        Header: 'Phone Number',
        accessor: 'phone',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Number of Angels',
        accessor: 'numberOfAngels',
      },
    ],
  },
  {
    Header: 'Drop-off Information',
    columns: [
      {
        Header: 'Confirmation ID',
        accessor: 'dropoffId',
      },
      {
        Header: 'Dropoff Location',
        accessor: 'dropoffLocation',
      },

      {
        Header: 'Has Dropped Off?',
        accessor: 'dropoff',
      },
      {
        Header: 'Dropoff Time',
        accessor: 'dropoffTime',
        Cell: ({ value }) => {
          return value && format(new Date(value), 'MMM d, yyyy H:mm');
        },
      },
    ],
  },
];
