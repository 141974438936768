import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { IoAddCircleOutline, IoArrowBack, IoSend } from 'react-icons/io5';
import { VscDebugRestart } from 'react-icons/vsc';

// Import React-Hook-Forms and yup
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';
import Select from 'react-select';
import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';

// Import sponsor actions
import { submitSponsorFormToServer } from '../sponsorFormSlice';
import { fetchChildInfo } from '../../child/childInfoSlice';

const schema = yup.object().shape(
  {
    name: yup
      .string()
      .matches(/^(\D*)$/, 'Name should not container number')
      .required('Full Name Required'),
    email: yup
      .string()
      .email('Incorrect Email Format')
      .required('Email Required'),
    phone: yup
      .string()
      .required('Phone number required')
      .min(14, 'Phone has 10 digits')
      .max(14, 'Phone has 10 digits'),
    title: yup.string().required('Title required'),
    selectedChildren: yup.array().of(
      yup
        .object()
        .shape({
          label: yup.string().nullable(true),
          value: yup.string().nullable(true),
        })
        .nullable(true)
    ),

    dropoffLocation: yup.string().required('Drop-off Location required'),
  }
  // Add Cyclic deps here because when require itself
);

const NewSponsorForm = () => {
  const [isSponsorship, setIsSponsorship] = useState(false);

  const navigate = useNavigate();
  const sponsorForm = useSelector((state) => state.sponsor.sponsorForm);
  const childInfo = useSelector((state) => state.child.childInfo);
  const dispatch = useDispatch();

  const { loading, error } = sponsorForm;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      title: '',
      dropoffLocation: '',
      sendEmail: '',
      selectedChildren: [],
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const normalizePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value, 'CA');
    if (!phoneNumber) return value;
    return phoneNumber.formatNational();
  };

  const handleSponsorship = () => {
    setIsSponsorship((prev) => !prev);
    dispatch(fetchChildInfo())
      .unwrap()
      .then(() => {
        return;
      })
      .catch((err) => {
        return;
      });
  };

  const childrenOptions = useMemo(() => {
    if (childInfo.data.length === 0) return [];

    const childrenArray = childInfo.data.map((child) => {
      return {
        value: child.childId,
        label: `${child.childName}, Angel ID: ${child.childId}`,
      };
    });
    childrenArray.sort((a, b) => a.label.localeCompare(b.label)); // sort by childName

    return childrenArray;
  }, [childInfo.data]);

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to add this new Sponsor?')) {
      const formData = {
        ...values,
      };

      // delete selectedChildren in formData to get childIds only
      delete formData.selectedChildren;

      const selectedChildrenIds = values.selectedChildren.map(
        (selectedChild) => selectedChild.value
      );

      formData.selectedChildrenIds = selectedChildrenIds;

      dispatch(submitSponsorFormToServer(formData))
        .unwrap()
        .then((message) => {
          reset();
          toast.success(message);
          navigate('/sponsor');
        })
        .catch((err) => {
          reset();
          toast.error(err);
        });
    }
  };

  return (
    <Container className='mt-5'>
      <div>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1>
          <span>
            <IoAddCircleOutline size={50} />
          </span>{' '}
          Add New Sponsor
        </h1>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Parent Information */}
        <Alert className='mt-3' variant='secondary'>
          <h4>Sponsor's Information</h4>
        </Alert>

        {/* Full Name */}
        <Form.Group className='mb-3' controlId='formBasicName'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type='text'
            {...register('name')}
            placeholder='Enter Full Name'
            isValid={dirtyFields?.name && !errors.name}
            isInvalid={errors?.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email Address */}
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            {...register('email')}
            placeholder='Enter Email'
            isValid={dirtyFields?.email && !errors.email}
            isInvalid={errors?.email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Phone Number (using Controller for formatting the phone number) */}
        <Form.Group className='mb-3' controlId='formBasicPhone'>
          <Form.Label>Phone Number</Form.Label>
          <Controller
            name='phone'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Control
                {...field}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(event) =>
                  field.onChange(normalizePhoneNumber(event.target.value))
                }
                isValid={dirtyFields?.phone && !errors.phone}
                isInvalid={errors?.phone}
              />
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* What describes the sponsor the best */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>What describes the sponsor the best?</Form.Label>
          <Controller
            name='title'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={dirtyFields?.title && !errors.title}
                isInvalid={errors?.title}
              >
                <option value=''>Please select a title</option>
                <option value='Student'>Student</option>
                <option value='Administration/Staff'>
                  Administration/Staff
                </option>
                <option value='Faculty'>Faculty</option>
                <option value='Alumni'>Alumni</option>
                <option value='External'>External</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.title?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Dropoff Location */}
        <Form.Group className='mb-3' controlId='formBasicLocation'>
          <Form.Label>Dropoff Location</Form.Label>
          <Controller
            name='dropoffLocation'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Form.Select
                {...field}
                aria-label='Default select location'
                isValid={
                  dirtyFields?.dropoffLocation && !errors.dropoffLocation
                }
                isInvalid={errors?.dropoffLocation}
              >
                <option value=''>Please select a pickup location</option>
                <option value='Abbotsford'>
                  Abbotsford: Abby Campus
                </option>
                <option value='Chilliwack'>
                  Chilliwack: CEP Campus
                </option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors?.dropoffLocation?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Send Confirmation email? */}
        {/* Approved Status. False by default */}
        <Form.Group className='mb-3' controlId='formApproved'>
          <Form.Label className='me-3'>Send Confirmation Email?</Form.Label>
          <Form.Check
            inline
            type='radio'
            name='sendEmailGroup'
            id='inline-radio-email-1'
            value={1}
            label='Yes'
            {...register('sendEmail')}
          />
          <Form.Check
            inline
            type='radio'
            name='sendEmailGroup'
            id='inline-radio-email-2'
            value={0}
            label='No'
            {...register('sendEmail')}
            defaultChecked
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formApproved'>
          <Form.Label className='me-3'>
            Add Angel(s) to this sponsor?
          </Form.Label>
          <Form.Check
            inline
            type='switch'
            id='custom-switch'
            value={isSponsorship}
            onChange={() => handleSponsorship()}
          />
        </Form.Group>

        {/* Children Sponsorship */}
        {isSponsorship && (
          <>
            <Alert className='mt-3' variant='secondary'>
              <h4>Angel Sponsorship </h4>
            </Alert>

            {childInfo.loading ? (
              <div className='d-flex flex-column justify-content-center align-items-center mb-3'>
                <Spinner animation='border' role='status' variant='success'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {/* Input children by childId */}
                {childInfo.data.length > 0 ? (
                  <>
                    <Form.Group className='mb-3' controlId='formBasicName'>
                      <Form.Label>Angel(s) (optional)</Form.Label>

                      <Controller
                        name='selectedChildren'
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable={true}
                            isClearable={true}
                            options={childrenOptions}
                            isMulti
                            placeholder='Select an Angel'
                          />
                        )}
                      />

                      <p className='mt-2 text-muted'>
                        Tips: If you select multiple Angels for this sponsor,
                        click on each Angel in the dropdown list or search Angel
                        name
                      </p>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Alert variant='warning'>
                      There are no Angels available. Please add a new Angel
                      before adding Angel to this Sponsor
                    </Alert>
                  </>
                )}
              </>
            )}
          </>
        )}

        <div className='d-grid gap-2'>
          <Button
            variant='outline-secondary'
            type='reset'
            onClick={() => setIsSponsorship(false)}
            size='lg'
          >
            Reset Form <VscDebugRestart />
          </Button>
          <Button variant='primary' type='submit' size='lg' disabled={loading}>
            {loading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              <span>
                Submit Form <IoSend />
              </span>
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewSponsorForm;
