import React, { useState } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IoLogInOutline } from 'react-icons/io5';
// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
import { loginAccount } from './authSlice';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const LoginPage = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const [userLogin, setUserLogin] = useState({
    emailOrUsername: '',
    passwd: '',
  });

  const { emailOrUsername, passwd } = userLogin;

  const { loading, isAuthenticated } = authState;

  const onChange = (e) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const data = {};

    if (emailPattern.test(emailOrUsername)) data.email = emailOrUsername;
    else data.username = emailOrUsername;

    data.passwd = passwd;

    dispatch(loginAccount(data))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  if (isAuthenticated)
    return <Navigate to='/' state={{ from: location }} replace />;

  return (
    <Container className='mt-5 p-5' fluid='md'>
      <Card>
        <Card.Body>
          <Card.Title className='text-center'>
            <IoLogInOutline size={100} />
            <h2 className='mt-3'>Login Account</h2>
          </Card.Title>
          <Form onSubmit={onSubmit} className='w-100 mt-3'>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Email or Username</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='text'
                  name='emailOrUsername'
                  required
                  placeholder='UFV SUS email or username'
                  value={userLogin.emailOrUsername}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Label className='w-75'>Password</Form.Label>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Form.Control
                  type='password'
                  name='passwd'
                  required
                  placeholder='Password'
                  value={userLogin.passwd}
                  onChange={(e) => onChange(e)}
                  className='w-75'
                />
              </div>
            </Form.Group>
            <div className='text-center p-3'>
              <a href='/auth/forgotpassword'>Forgot Password?</a>
            </div>
            <div className='text-center p-3'>
              <Button
                variant='primary'
                type='submit'
                size='lg'
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            </div>

            <div className='text-center p-3'>
              Not a member? <a href='/auth/register'>Register now</a>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LoginPage;
