import React from 'react';
import { Outlet } from 'react-router-dom';
import NaviBar from './NaviBar';
import Footer from './Footer';

const Layout = () => {
  return (
    <>
      <div style={{ minHeight: 'calc(100vh - 2rem)' }}>
        <NaviBar />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
