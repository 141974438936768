import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// Import useSelector and useDispatch to manage the global state
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const RequireAuth = ({ children }) => {
  const authState = useSelector((state) => state.auth);

  const { isAuthenticated, loading } = authState;
  let location = useLocation();

  if (loading) {
    return (
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <Spinner animation='border' role='status' variant='success'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to='/auth/login' state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
