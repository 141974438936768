import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import TokenService from '../../services/TokenService';

const accessToken = TokenService.getLocalAccessToken();

const initialAuthState = {
    loading: false,
    error: null,
    accessToken: accessToken !== null ? accessToken : null,
    isAuthenticated: false,
    user: null,
};

export const registerAccount = createAsyncThunk('auth/registerAccount', async (data, { rejectWithValue }) => {
    try {
        const res = await api.post('/auth/register', data);

        return res.data?.message;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message)
    }
});

export const loginAccount = createAsyncThunk('auth/loginAccount', async (data, { rejectWithValue, dispatch }) => {
    const formData = {
        ...data,
        role: 'admin',
    };

    try {
        const res = await api.post('/auth/login-admin', formData);
        TokenService.updateLocalAccessToken(res.data?.accessToken);

        await dispatch(getCurrentUser());

        return res.data?.accessToken;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Invalid Credentials!');
    }
});

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (_, { rejectWithValue }) => {
    try {
        const res = await api.get('/auth/me');
        return res.data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Something went wrong!')
    }
});

export const logoutAccount = createAsyncThunk('auth/logoutAccount', async (_, {rejectWithValue, dispatch}) => {
    try {
        await api.post('/auth/logout');
        // Remove token from localstorage
        TokenService.removeToken();
        dispatch(logoutSuccess());

        return;
    } catch (error) {
        return rejectWithValue(error.response?.message || 'Something went wrong');
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        refreshToken: (state, action) => {
            state.accessToken = action.payload;
        },
        logoutSuccess: (state) => {
            Object.assign(state, initialAuthState);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(registerAccount.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(registerAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(loginAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginAccount.fulfilled, (state, payload) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.accessToken = payload.accessToken;
            })
            .addCase(loginAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getCurrentUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.user = action.payload.user;
            })
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(logoutAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(logoutAccount.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(logoutAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

    }
});

export const {refreshToken, logoutSuccess} = authSlice.actions;

export default authSlice.reducer;