import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
  Form,
  Badge,
  Spinner,
  Card,
  ListGroup,
} from 'react-bootstrap';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

// Import React-Hook-Forms and yup
import { useForm, useFieldArray } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
// Import parent actions
import { toast } from 'react-toastify';
import { selectManyParents, updateParentsPickupStatus } from '../parentFormSlice';
import { format } from 'date-fns';

const PickupStatus = () => {
  const navigate = useNavigate();

  const parentForm = useSelector((state) => state.parent.parentForm);
  const dispatch = useDispatch();

  const { selectedParents, updateLoading } = parentForm;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
    watch,
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'selectedParents',
  });

  useEffect(() => {
    if (selectedParents.length > 0) {
      const updatedParents = selectedParents.map((parent) => {
        const updatedParent = { ...parent }; // Create a shallow copy of the parent object

        // Update the 'approved' property
        if (updatedParent.approved === 'Yes') {
          updatedParent.approved = 1;
        } else {
          updatedParent.approved = 0;
        }

        // Update the 'pickup' property
        if (updatedParent.pickup === 'Yes') {
          updatedParent.pickup = 1;
        } else {
          updatedParent.pickup = 0;
        }

        return updatedParent; // Return the updated parent object
      });

      setValue('selectedParents', updatedParents);
    }
  }, [setValue, selectedParents]);


  const watching = watch('selectedParents');

  const onSubmit = (values) => {
    if (window.confirm('Do you really want to update these Parent Pick-up Statuses?')) {
      dispatch(updateParentsPickupStatus(values.selectedParents))
        .unwrap()
        .then((msg) => {
          toast.success(msg);
          navigate('/parent', { replace: true });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  if (selectedParents.length === 0)
    return (
      <Container className='mt-5'>
        <div>
          <Button
            variant='outline-secondary'
            className='mb-3'
            onClick={() => {
              navigate('/parent');
            }}
          >
            <IoArrowBack /> Go Back
          </Button>
          <h1 className='mb-5'>
            <span>
              <FiEdit size={50} />
            </span>{' '}
            Update Pickup
          </h1>
        </div>
        <Alert variant='warning'>
          No Parent(s) selected. Please select more than 2 parents .
        </Alert>
      </Container>
    );

  return (
    <Container className='mt-5'>
      <div>
        <Button
          variant='outline-secondary'
          className='mb-3'
          onClick={() => {
            dispatch(selectManyParents([]));
            navigate(-1);
          }}
        >
          <IoArrowBack /> Go Back
        </Button>
        <h1 className='mb-5'>
          <span>
            <FiEdit size={50} />
          </span>{' '}
          Update Pickup{' '}
          <span>
            <Badge style={{ fontSize: '22px' }} pill bg='secondary'>
              {selectedParents.length}
            </Badge>
          </span>
        </h1>
      </div>
      {fields.length > 0 && (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          {fields.map((item, index) => (
            <Card key={item.id} className='border-light mb-5'>
              <Card.Header>
                <h4>
                  {item.parentName} -{' '}
                  <span className='text-danger fw-bold'>
                    ID: {item.parentId}
                  </span>
                </h4>
              </Card.Header>
              <Card.Body>
                {/* Parent Information */}
                <ListGroup variant='flush'>
                  <ListGroup.Item className='d-flex'>
                    Student ID
                    <span className='fw-bold ms-auto'>{item.studentId}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex flex-wrap'>
                    Email
                    <span className='fw-bold ms-auto'>{item.email}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Number of Angels
                    <span className='fw-bold ms-auto'>
                      {item.numberOfAngels}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Pickup Location
                    <span className='fw-bold ms-auto'>
                      {item.pickupLocation} campus
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Additional Person
                    <span className='fw-bold ms-auto'>
                      {item.additionalPerson}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex'>
                    Pickup Time
                    <span className='fw-bold ms-auto'>
                      {item.pickupTime !== null
                        ? format(new Date(item.pickupTime), 'dd/MM/yyyy p')
                        : 'None'}
                    </span>
                  </ListGroup.Item>
                  {/* Pickup Status */}
                  {item.approved === 1 ? (
                    <ListGroup.Item>
                      <Form.Group
                        className='d-flex mb-3'
                        controlId='formApproved'
                      >
                        <Form.Label className='me-3'>
                          Picked Up Angel Wish?
                        </Form.Label>

                        <div className='ms-auto'>
                          <Form.Check
                            inline
                            type='radio'
                            name='pickupGroup'
                            id='inline-radio-pickup'
                            value={1}
                            label='Yes'
                            defaultChecked={item.pickup === 1}
                            {...register(`selectedParents.${index}.pickup`)}
                          />
                          <Form.Check
                            inline
                            type='radio'
                            name='pickupGroup'
                            id='inline-radio-pickup'
                            value={0}
                            label='No'
                            defaultChecked={item.pickup === 0}
                            {...register(`selectedParents.${index}.pickup`)}
                          />
                        </div>
                      </Form.Group>
                    </ListGroup.Item>
                  ) : (
                    <Alert variant='warning'>
                      This Parent/Caregiver has not been approved before picking
                      up the wishes.
                    </Alert>
                  )}

                  {/* Send Confirmation email? */}
                  {(item.approved === 1 && item.pickup === 1) ||
                    (item.approved === 1 && watching[index].pickup === '1') ? (
                    <ListGroup.Item>
                      <Form.Group
                        className='d-flex mb-3'
                        controlId='formApproved'
                      >
                        <Form.Label className='me-3'>
                          Send Pickup Confirmation Email?
                        </Form.Label>

                        <div className='ms-auto'>
                          <Form.Check
                            inline
                            type='radio'
                            name='sendEmailGroup'
                            id='inline-radio-email-1'
                            value={1}
                            label='Yes'
                            {...register(`selectedParents.${index}.sendEmail`)}
                          />
                          <Form.Check
                            inline
                            type='radio'
                            name='sendEmailGroup'
                            id='inline-radio-email-2'
                            value={0}
                            label='No'
                            {...register(`selectedParents.${index}.sendEmail`)}
                            defaultChecked
                          />
                        </div>
                      </Form.Group>
                    </ListGroup.Item>
                  ) : (
                    <></>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          ))}

          <div className='d-grid gap-2'>
            <Button
              variant='success'
              type='submit'
              size='lg'
              disabled={updateLoading || !isDirty}
            >
              {updateLoading ? (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                <span>
                  Save Changes <IoSave />
                </span>
              )}
            </Button>
          </div>
        </Form>
      )}
    </Container>
  );
};

export default PickupStatus;
